<template>
  <a-modal :title="`支付第一次入驻服务费(${props.tableColumnsData.full_name})`" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <a-popconfirm title="是否确定？" @confirm="onFormSubmit">
        <a-button type="primary" :loading="state.submitLoading">确定</a-button>
      </a-popconfirm>
    </template>
    <a-skeleton :loading="state.loading" active :paragraph="{ rows: 6 }">
      <a-form ref="ruleForm" :model="formData" v-bind="formLayout">
        <a-row>
          <a-col :span="24">
            <a-form-item label="入驻地址">
              {{ props.tableColumnsData.full_address_number }}
            </a-form-item>
          </a-col>
          <a-col :span="8" :offset="3">
            <a-form-item label="入驻开始时间" :labelCol="{ span: 9 }">
              {{ props.tableColumnsData.enter_starttime }}
            </a-form-item>
          </a-col>
          <a-col :span="11" :offset="1">
            <a-form-item label="入驻结束时间">
              {{ props.tableColumnsData.enter_endtime }}
            </a-form-item>
          </a-col>
          <a-col :span="8" :offset="4">
            <a-form-item label="收费类型" v-if="props.tableColumnsData.payment_type == 3">
              {{ props.tableColumnsData.other_name }}
            </a-form-item>
            <a-form-item label="收费类型" v-else> 【{{ props.tableColumnsData.fee_standard == 2 ? '一次性收费' : '按年收费' }}】 </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item label="代理商姓名">
              {{ props.tableColumnsData.seller }}
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="props.tableColumnsData.from == undefined">
          <a-form-item label="付款方式">
            <a-radio-group v-model:value="formData.type" button-style="solid">
              <a-radio :value="2">余额抵扣</a-radio>
              <!-- <a-radio :value="1">微信扫码付款</a-radio> -->
              <a-radio :value="3">库存抵扣</a-radio>
              <a-radio :value="4">对公账户</a-radio>
            </a-radio-group>
          </a-form-item>
          <div v-if="formData.type == 4">
            <a-form-item label="付款人全称" name="public_payer_name" required>
              <a-input v-model:value="formData.public_payer_name" placeholder="请输入付款人全称" style="width: 400px"></a-input>
            </a-form-item>
            <a-form-item label="付款日期" name="public_pay_time" required>
              <a-date-picker v-model:value="formData.public_pay_time" format="YYYY-MM-DD" placeholder="请选择付款日期" style="width: 400px"></a-date-picker>
            </a-form-item>
            <a-form-item label="付款金额" name="public_money" required>
              <a-input v-model:value="formData.public_money" placeholder="请输入付款金额" style="width: 400px"></a-input>
            </a-form-item>
            <a-form-item label="付款备注" name="public_remark" required>
              <a-input v-model:value="formData.public_remark" placeholder="请输入付款备注" style="width: 400px"></a-input>
            </a-form-item>
            <a-form-item label="收款人全称" name="public_payee_name" required>
              <a-input v-model:value="formData.public_payee_name" placeholder="请输入收款人全称" style="width: 400px"></a-input>
            </a-form-item>
            <a-form-item label="附件" name="attachment" required>
              <Upload
                :upload_path="props.tableColumnsData.upload_path"
                :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
                uploadType="text"
                v-model:value="formData.attachment"
                :limitation="99"
                uploadText="上传附件"
              >
              </Upload>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea v-model:value="formData.remark" placeholder="请输入备注" style="width: 400px"></a-textarea>
            </a-form-item>
          </div>

          <a-form-item v-if="formData.type == 1" label="扫码付款">
            <a-qrcode :status="state.qrcode?.length > 0 ? 'active' : 'loading'" :value="state.qrcode" :size="200"></a-qrcode>
          </a-form-item>
          <div v-else-if="formData.type == 2">
            <a-form-item label="余额">
              ￥{{ state.moeny }}
              <a @click="recharge" class="m-3x">点击充值</a>
            </a-form-item>

            <PlatformPayment v-if="state.platformVisible" @cancel="handleOk" @ok="handleOk" :tableColumnsData="props.tableColumnsData"></PlatformPayment>

            <a-form-item label="需要支付金额">
              <div style="font-size: 24px; color: red; line-height: 24px">
                <div v-if="state.coupon_use_id >= 1">
                  <span class="red" style="text-decoration: line-through">￥{{ state.pay_amount_total }} </span>
                  <span>，优惠了￥{{ state.discount_amount_total }}</span>
                  <a-button type="primary" @click="useCoupon" style="background: #ff6b26; margin-left: 10px" size="small" v-if="state.isselected_coupon">
                    使用了优惠券（{{ state.isselected_coupon.coupon_type == 1 ? '直减现金券' : state.isselected_coupon.coupon_type == 2 ? '满减现金券' : '折扣券' }}：{{
                      state.isselected_coupon.name
                    }}√）</a-button
                  >
                  <a style="margin-left: 20px; font-size: 14px" @click="deleteCoupon">删除优惠券</a>
                </div>
                <div v-else>￥{{ props.tableColumnsData.PayMoney }}<a-button type="primary" @click="useCoupon" style="background: #ff6b26; margin-left: 10px" size="small">使用优惠券</a-button></div>
              </div>
            </a-form-item>
            <a-form-item label="优惠后需要支付金额" v-if="state.coupon_use_id >= 1">
              <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ state.actually_paid_total }}</div>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea v-model:value="formData.remark" placeholder="请输入备注" style="width: 400px"></a-textarea>
            </a-form-item>
          </div>

          <a-form-item v-else-if="formData.type == 3" label="选择库存" :wrapperCol="{ xs: { span: 24 }, sm: { span: 15 } }">
            <a-select placeholder="请选择库存" v-model:value="state.kucunId">
              <a-select-option :key="item.id" :value="item.id" v-for="item in state.addr_kucun"
                >{{ item.promotion_name }};当时购买价:￥{{ item.promotion_register_price }};过期时间{{ item.end_time }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </div>
        <div v-else-if="props.tableColumnsData.payment_type == 1">
          <a-row>
            <a-col :span="8" :offset="3">
              <a-form-item label="需要支付金额" :labelCol="{ span: 9 }">
                <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.PayMoney }}</div>
              </a-form-item>
              <div v-if="state.coupon_use_id >= 1">
                <span class="red" style="text-decoration: line-through">￥{{ state.pay_amount_total }} </span>
                <a-form-item label="需要支付金额" :labelCol="{ span: 9 }">
                  <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ state.actually_paid_total }}</div>
                </a-form-item>
                <span>优惠{{ state.discount_amount_total }}元</span>
              </div>
              <div v-else>
                <a-form-item label="需要支付金额" :labelCol="{ span: 9 }">
                  <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.PayMoney }}</div>
                </a-form-item>
              </div>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-form-item label="实际对公转账">
                <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.RealPayMoney }}</div>
              </a-form-item>
            </a-col></a-row
          >
          <a-form-item label="审核结果">
            <a-radio-group v-model:value="state.result" button-style="solid">
              <a-radio :value="1">通过</a-radio>
              <a-radio :value="2">不通过</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="执行动作" v-if="state.result == 1">
            <a-radio-group v-model:value="state.doaction" button-style="solid">
              <a-radio :value="2">支付注册费</a-radio>
              <a-radio :value="1">不操作</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea v-model:value="formData.remark" placeholder="请输入备注" style="width: 400px"></a-textarea>
          </a-form-item>
        </div>
        <div v-else-if="props.tableColumnsData.payment_type == 2">
          <a-row>
            <a-col :span="8" :offset="3">
              <div v-if="state.coupon_use_id >= 1">
                <span class="red" style="text-decoration: line-through">￥{{ state.pay_amount_total }} </span>
                <a-form-item label="需要支付金额" :labelCol="{ span: 9 }">
                  <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ state.actually_paid_total }}</div>
                </a-form-item>
                <span>优惠{{ state.discount_amount_total }}元</span>
              </div>
              <div v-else>
                <a-form-item label="需要支付金额" :labelCol="{ span: 9 }">
                  <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.PayMoney }}</div>
                </a-form-item>
              </div>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-form-item label="实际对公转账">
                <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.RealPayMoney }}</div>
              </a-form-item>
            </a-col></a-row
          >
          <a-form-item label="审核结果">
            <a-radio-group v-model:value="state.result" button-style="solid">
              <a-radio :value="1">通过</a-radio>
              <a-radio :value="2">不通过</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="执行动作" v-if="state.result == 1">
            <a-radio-group v-model:value="state.doaction2" button-style="solid" :defaultValue="3">
              <a-radio :value="3">地址续费</a-radio>
              <a-radio :value="1">不操作</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="续费类型" v-if="state.result == 1" required>
            <a-radio-group button-style="solid" v-model:value="props.tableColumnsData.fee_standard">
              <a-radio-button :value="1">按年续费</a-radio-button>
              <a-radio-button :value="2">一次性续费</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="续费时长" v-if="state.result == 1" name="year_quantity" required>
            <a-select placeholder="请选择续费时长" style="width: 400px" v-model:value="state.year_quantity">
              <a-select-option v-for="(item, index) in timesOption" :key="item.value"> {{ item.label }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea v-model:value="formData.remark" placeholder="请输入备注" style="width: 400px"></a-textarea>
          </a-form-item>
        </div>
        <div v-else-if="props.tableColumnsData.payment_type == 3">
          <a-row>
            <a-col :span="8" :offset="3">
              <a-form-item label="需要支付金额" :labelCol="{ span: 9 }">
                <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.other_amount }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-form-item label="实际对公转账">
                <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ props.tableColumnsData.RealPayMoney }}</div>
              </a-form-item>
            </a-col></a-row
          >
          <a-form-item label="审核结果">
            <a-radio-group v-model:value="state.result" button-style="solid">
              <a-radio :value="1">通过</a-radio>
              <a-radio :value="2">不通过</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea v-model:value="formData.remark" placeholder="请输入备注" style="width: 400px"></a-textarea>
          </a-form-item>
        </div>
      </a-form>
    </a-skeleton>
  </a-modal>
  <UseCoupons
    v-if="state.showCoupon"
    v-model:couponId="state.coupon_use_id"
    :tableColumnsData="{ public_payment_type: 1, customer_full_names: null, id: props.tableColumnsData.id }"
    @cancel="
      () => {
        state.showCoupon = false
      }
    "
    @ok="UseCouponsOk"
  ></UseCoupons>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { reactive, onMounted, ref } from 'vue'
import PlatformPayment from './PlatformPayment.vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import Upload from '@/components/base/Upload.vue'
import UseCoupons from '@/components/base/UseCoupons.vue'
const format = 'YYYY-MM-DD'
const $store = useStore()
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}
const ruleForm = ref()
const emit = defineEmits(['cancel', 'ok'])
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const timesOption = [
  { label: '1个月', value: 1 },
  { label: '2个月', value: 2 },
  { label: '3个月', value: 3 },
  { label: '4个月', value: 4 },
  { label: '5个月', value: 5 },
  { label: '6个月', value: 6 },
  { label: '7个月', value: 7 },
  { label: '8个月', value: 8 },
  { label: '9个月', value: 9 },
  { label: '10个月', value: 10 },
  { label: '11个月', value: 11 },
  { label: '1年', value: 12 },
  { label: '2年', value: 24 },
  { label: '3年', value: 36 },
  { label: '4年', value: 48 },
  { label: '5年（一次性）', value: 60 }
]
const state = reactive<any>({
  qrcode: '',
  moeny: undefined,
  loading: false,
  submitLoading: false,
  addr_kucun: [],
  kucunId: undefined,
  platformVisible: false,
  showCoupon: false,
  doaction: 2,
  doaction2: 3,
  result: 1,
  year_quantity: 12,
  fee_standard: null,
  discount_amount_total: 0, // 总优惠金额
  pay_amount_total: 0, // 总应付金额
  actually_paid_total: 0, // 总实付金额
  customer_xfdata: [],

  coupon_use_list: [], // 优惠券列表
  coupon_use_id: null, // 选中优惠券id
  isselected_coupon: {} //选中的优惠券
})
const formData = reactive<any>({
  type: 2
})
const useCoupon = () => {
  state.showCoupon = true
}
onMounted(() => {
  //   queryDetail()
  if (props.tableColumnsData.from != 1) {
    //如果不是对公转账审核则请求余额
    queryMoney()
    getComputeOreder()
  }
})
const UseCouponsOk = (e) => {
  state.coupon_use_id = e.id
  state.isselected_coupon = e
  state.showCoupon = false
  getComputeOreder()
}
const deleteCoupon = () => {
  state.isselected_coupon = {}
  state.coupon_use_id = null
  getComputeOreder()
}
// 计算订单-支付第一次入驻服务费
const getComputeOreder = () => {
  state.priceLoading = true
  let requeryParam = {
    url: `/admin/v1/getComputeOreder`,
    method: 'post',
    data: {
      public_payment_type: 1, //支付注册费
      customer_id: props.tableColumnsData.id,
      month_quantity: 12,
      fee_standard: props.tableColumnsData.fee_standard, //按年收费
      coupon_use_id: state.coupon_use_id
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.priceLoading = false
      state.coupon_use_list = res.data.coupon_use_list
      state.discount_amount_total = res.data.oreder.discount_amount_total // 总优惠金额
      state.pay_amount_total = res.data.oreder.pay_amount_total // 总应付金额
      state.actually_paid_total = res.data.oreder.actually_paid_total // 总实付金额
      state.customer_xfdata = res.data.oreder.customer_xfdata // 总实付金额
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '查询订单失败')
      state.priceLoading = false
      if (res?.data == 'coupon') {
        //优惠券问题，删除此优惠券
        deleteCoupon()
      }
    })
}

const recharge = () => {
  state.platformVisible = true
}
const handleOk = () => {
  state.platformVisible = false
  //刷新余额
  queryMoney()
}
const queryDetail = () => {
  let requeryParam = {
    url: '/admin/customerWchatPay?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requeryParam)
    .then((res) => {
      state.qrcode = res.data.code_url
    })
    .catch((err) => {
      if (err.msg) {
        message.error(err.msg)
      } else {
        message.error('获取二维码失败')
      }
    })
}

const queryMoney = () => {
  state.loading = true
  let requeryParam = {
    url: '/admin/user/getMoney?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requeryParam)
    .then((res) => {
      state.loading = false
      state.moeny = res.data.money
      state.addr_kucun = res.data.addr_kucun
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取余额失败')
      }
    })
}

// 提交
const onFormSubmit = () => {
  if (props.tableColumnsData.from == undefined && formData.type !== null) {
    state.submitLoading = true
    let obj: any = {}
    if (formData.type == 4) {
      //对公转账
      obj = {
        public_payment_type: 1, //支付注册费
        customer_id: props.tableColumnsData.id, //客户id
        fee_standard: props.tableColumnsData.fee_standard, //收费时间按照主表12个月
        month_quantity: null,
        belonger: props.tableColumnsData.seller, //代理商
        coupon_use_id: null, //对公不能使用优惠券
        pay_type: 4, //对公转账
        remark: formData.remark, //付款备注
        attachment: formData.attachment, //附件、对公才传附件

        public_payer_name: formData.public_payer_name, //对公转账特有的6项
        public_payee_number: formData.public_payee_number,
        public_money: formData.public_money,
        public_remark: formData.public_remark,
        public_payee_name: formData.public_payee_name,
        public_pay_time: dayjs(formData.public_pay_time).format('YYYY-MM-DD')
      }
    } else if (formData.type == 2) {
      //余额抵扣
      obj = {
        public_payment_type: 1, //支付注册费
        customer_id: props.tableColumnsData.id, //客户id
        fee_standard: props.tableColumnsData.fee_standard, //收费时间按照主表12个月
        month_quantity: null,
        belonger: props.tableColumnsData.seller, //代理商
        coupon_use_id: state.coupon_use_id, //余额抵扣可以用优惠券
        pay_type: 1, //余额抵扣
        remark: formData.remark //付款备注
      }
    } else if (formData.type == 3) {
      //库存抵扣
      obj = {
        public_payment_type: 1, //支付注册费
        customer_id: props.tableColumnsData.id, //客户id
        fee_standard: props.tableColumnsData.fee_standard, //收费时间按照主表12个月
        month_quantity: null,
        belonger: props.tableColumnsData.seller, //代理商
        coupon_use_id: null, //库存抵扣不能使用优惠券
        pay_type: 5, //库存抵扣
        remark: formData.remark, //付款备注
        kc_id: state.kucunId //库存抵扣特有的
      }
    }
    let requeryParam = {
      url: '/admin/v1/payOreder',
      method: 'post',
      data: obj
    }
    baseService(requeryParam)
      .then((res: any) => {
        message.success(res.msg)
        emit('ok')
        state.submitLoading = false
      })
      .catch((res) => {
        state.submitLoading = false
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  } else {
    if (state.doaction >= 1) {
      let requeryParam = {
        url: '/admin/v1/publicARecharge/check',
        method: 'post',
        data: {
          type: state.doaction,
          id: props.tableColumnsData.recharge_log_id,
          operating_remarks: formData.remark,
          status: state.result,
          operating_month_amount: '这里是续费月份'
        }
      }
      if (props.tableColumnsData.payment_type == 3) {
        requeryParam.url = '/admin/v1/publicARecharge/check3'
      }
      baseService(requeryParam)
        .then((res: any) => {
          message.success(res.msg)
          emit('ok')
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    }
  }
}

const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-modal-title) {
  margin-bottom: 25px !important;
}
.label-form-item {
  display: flex;
  align-items: center;

  .delete-icon {
    color: red;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.select-color {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .label-color-picker {
    border: 1px solid #dcdfe6;
    padding: 4px;
    border-radius: 4px;
  }
}
</style>
