<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createMenu',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="800"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="菜单类型" name="is_menu">
          <a-radio-group v-model:value="formState.is_menu">
            <a-radio :value="1">菜单</a-radio>
            <a-radio :value="0">按钮</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="上级权限" name="parent_name">
          <a-input placeholder="请输入上级权限标识" v-model:value="formState.parent_name"></a-input>
          如：customer
        </a-form-item>
        <a-form-item label="菜单名称" name="display_name">
          <a-input placeholder="请输入菜单名称" v-model:value="formState.display_name"></a-input>
          如：客户管理
        </a-form-item>
        <a-form-item label="权限标识" name="name">
          <a-input placeholder="请输入菜单名称" v-model:value="formState.name"></a-input>
          如：customer
        </a-form-item>
        <div v-if="formState.is_menu == 1">
          <a-form-item label="路由路径" name="path">
            <a-input placeholder="请输入权限标识" v-model:value="formState.path"></a-input>
            如：/customer
          </a-form-item>
          <a-form-item label="重定向地址" name="redirect_address">
            <a-input placeholder="请输入重定向地址" v-model:value="formState.redirect_address"></a-input>
            如：/customer （顶级菜单如有子菜单时填写）
          </a-form-item>
          <a-form-item label="组件路径" name="route">
            <a-input placeholder="请输入组件路径" v-model:value="formState.route"></a-input>
            如：/views/customer/CustomerList.vue
          </a-form-item>
        </div>
        <a-form-item label="排序" name="weight"> <a-input placeholder="请输入排序" v-model:value="formState.weight"></a-input> 小靠前 </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createMenu">
import { reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
  display_name: [{ required: true, message: '请输入菜单名称', trigger: 'change' }],
  name: [{ required: true, message: '请输入权限标识', trigger: 'change' }],
  weight: [{ required: true, message: '请输入排序', trigger: 'change' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增菜单',
  loading: false
})
interface formState {
  name: string
  display_name: string
  path?: string
  redirect_address?: string
  route?: string
  parent_name: string | undefined
  is_menu: number
  weight: number
}
let form = ref<formState>({
  name: '',
  weight: 0,
  parent_name: '',
  path: '',
  route: '',
  redirect_address: '',
  is_menu: 1,
  display_name: ''
})
let formState = form.value

onMounted(() => {
  if (props.detailData?.id) {
    state.modalTitle = '编辑菜单'
    queryDetail(props.detailData.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/permission/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    form.value = res.data
    formState = form.value
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/permission/' + props.detailData.id + '/update',
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
            state.loading = false
          })
      } else {
        let requeryParam = {
          url: '/admin/permission/store',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
            state.loading = false
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
