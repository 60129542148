<template>
  <a-modal
    :keyboard="false"
    :maskClosable="false"
    title="新增电子化代办人账号"
    destroyOnClose="true"
    width="80%"
    :open="true"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-form ref="personForm" :model="state.tableData[0]" :rules="personRules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
      <div class="information">
        <div class="information-right">
          <a-spin :spinning="state.loading_front">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              accept=".jpeg,.png,.jpg"
              :before-upload="beforeUpload"
              @change="
                (info) => {
                  handleChange(info, 'id_card_front')
                }
              "
            >
              <img class="upload-img" type="" v-if="state.tableData[0].id_card_front" :src="$store.state.user.image_domain + state.tableData[0].id_card_front" alt="avatar" />
              <div v-else class="upload-img">
                <PlusOutlined />
                <div class="ant-upload-text">上传人员身份证照片(人像面)</div>
              </div>
            </a-upload>
          </a-spin>
          <a-spin :spinning="state.loading_back">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              accept=".jpeg,.png,.jpg"
              :before-upload="beforeUpload"
              @change="
                (info) => {
                  handleChange(info, 'id_card_back')
                }
              "
            >
              <img class="upload-img" v-if="state.tableData[0].id_card_back" :src="$store.state.user.image_domain + state.tableData[0].id_card_back" alt="avatar" />
              <div v-else class="upload-img">
                <PlusOutlined />
                <div class="ant-upload-text">上传人员身份证照片(国徽面)</div>
              </div>
            </a-upload>
          </a-spin>
        </div>
        <div class="disolay:block;">
          <a-form-item :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }" label="电子化账号" name="account">
            <a-input
              style="width: 200px"
              placeholder="请输入电子化账号"
              :value="state.tableData[0].account"
              @change="
                (v) => {
                  state.tableData[0].account = v.target.value
                }
              "
            ></a-input> </a-form-item
          ><a-form-item :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }" label="电子化密码" name="password">
            <a-input
              style="width: 200px"
              placeholder="请输入电子化密码"
              :value="state.tableData[0].password"
              @change="
                (v) => {
                  state.tableData[0].password = v.target.value
                }
              "
            ></a-input> </a-form-item
          ><a-form-item :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }" label="电子化手机号码" name="tel">
            <a-input
              @change="
                (v) => {
                  phoneChange(v, 'tel')
                }
              "
              style="width: 200px"
              placeholder="请输入手机号码"
              :value="state.tableData[0].tel"
            ></a-input>
          </a-form-item>
          <a-form-item :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }" label="备注" name="remark">
            <a-input
              @change="
                (v) => {
                  state.tableData[0].remark = v.target.value
                }
              "
              style="width: 200px"
              placeholder="请输入备注"
              :value="state.tableData[0].remark"
            ></a-input>
          </a-form-item>
        </div>
      </div>

      <a-table :columns="columns" :data-source="state.tableData" :pagination="false">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'idcard_num'">
            <span> 身份证号码 </span>
          </template>
          <template v-else-if="column.dataIndex === 'address'">
            <span> 住址 </span>
          </template>
        </template>
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'name'">
            <a-form-item class="m-0" name="name" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="姓名" v-model:value="record.name"></a-input>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'gender'">
            <a-form-item class="m-0" name="gender" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-select style="width: 80px" v-model:value="record.gender" placeholder="性别">
                <a-select-option :value="1"> 男</a-select-option>
                <a-select-option :value="2"> 女</a-select-option>
              </a-select>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'nationality'">
            <a-form-item class="m-0" name="nationality" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-select v-model:value="record.nationality" placeholder="民族">
                <a-select-option :value="item" v-for="(item, index) in state.nationalityList" :Key="index"> {{ item }}</a-select-option>
              </a-select>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'idcard_num'">
            <a-form-item class="m-0" name="idcard_num" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="身份证" v-model:value="record.idcard_num"></a-input>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'address'">
            <a-form-item class="m-0" name="address" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="住所" v-model:value="record.address"></a-input>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'starttime'">
            <a-form-item class="m-0" name="starttime" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-date-picker placeholder="身份证开始日期" :format="format" v-model:value="record.starttime" />
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'endtime'">
            <a-form-item class="m-0" name="endtime" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-form-item-rest>
                <a-date-picker placeholder="身份证结束日期" :format="format" v-model:value="record.endtime" style="width: 140px" class="mr-1x" :disabled="state.tableData[0].identity_perpetual == 1" />
              </a-form-item-rest>
              <a-form-item-rest>
                <a-checkbox :checked="state.tableData[0].identity_perpetual == 1 ? true : false" @change="identityPerpetualChange">长期</a-checkbox>
              </a-form-item-rest>
            </a-form-item>
          </template>
        </template>
      </a-table>
    </a-form>
    <ImageCropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :uploadPathObj="props.uploadPathObj"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      :customerName="props.customerName"
      :isTiqianClose="true"
      @change="cropperPost"
      v-if="state.showCropper"
    >
    </ImageCropper>
  </a-modal>
</template>

<script lang="ts" setup>
import baseService from '@/utils/http/axios'
import ImageCropper from '@/components/ImageCropper/index.vue'
import { Upload } from '@/components'
import dayjs from 'dayjs'
import { PlusOutlined } from '@ant-design/icons-vue'
import cloneDeep from 'lodash.clonedeep'
import { reactive, ref, computed, onMounted, PropType, onBeforeUnmount } from 'vue'
import { message } from 'ant-design-vue'
import { mobileRequiredValidator, mobileValidator } from '@/utils/util'
import type { Rule } from 'ant-design-vue/es/form'
import { useStore } from 'vuex'
let dayTime = dayjs()
const $store = useStore()
const format = 'YYYY-MM-DD'
const props = defineProps({
  // 人员数据
  employmentData: {
    type: Object as PropType<tableData>
  },
  // 是否编辑
  isEdit: {
    type: Boolean
  },
  // 总人员信息
  shareOwnershipTableData: {
    type: Array as PropType<any>
  },
  uploadPathObj: {
    type: Object
  },
  customerName: {
    type: String
  }
})

interface tableData {
  id?: number
  // 姓名
  name: string | null
  // 性别
  gender: string | null
  // 身份证号码
  idcard_num: string | null
  // 地址
  address: string | null
  // 民族
  nationality: string | null
  // 是否为长期
  identity_perpetual: number | null
  // 身份证日期开始时间
  starttime: any
  // 身份证日期截止时间
  endtime: any
  // 身份证正面照片
  id_card_front: string | null
  // 身份证反面照片
  id_card_back: string | null
  //账号密码
  account: string | null
  password: string | null
  // 手机号
  tel: string | null
  // 编辑或者新增
  type: string | null
}
const emit = defineEmits(['change', 'cancel'])
const personForm = ref()
let validateEnd = (rule, value) => {
  if (!value && state.tableData[0].identity_perpetual != 1) {
    return Promise.reject(new Error('请选择身份证结束日期'))
  } else if (value && state.tableData[0].identity_perpetual != 1) {
    const start = dayjs(value, 'YYYY-MM-DD')
    const diff = start.diff(dayTime, 'day')
    if (diff < 5) {
      return Promise.reject(new Error('身份证结束日期不能少于5天'))
    } else {
      return Promise.resolve()
    }
  } else {
    return Promise.resolve()
  }
}
const columns = [
  {
    // 默认货币
    title: '姓名',
    dataIndex: 'name',
    width: 80
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 50
  },

  {
    title: '身份证号码',
    dataIndex: 'idcard_num',
    width: 150
  },
  {
    title: '住所',
    dataIndex: 'address',
    width: 150
  },
  {
    title: '民族',
    dataIndex: 'nationality',
    width: 90
  },
  {
    title: '身份证有效期开始日期',
    dataIndex: 'starttime',
    width: 130
  },
  {
    title: '身份证有效期结束日期',
    dataIndex: 'endtime',
    width: 120
  }
]
const idNumberValidator = (rule, value) => {
  let reg_tel = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (value && value.length > 0) {
    if (!reg_tel.test(value)) {
      return Promise.reject('身份证号码不正确')
    } else {
      return Promise.resolve()
    }
  } else {
    return Promise.reject('请输入身份证号码')
  }
}
let personRules: Record<string, Rule[]> = {
  name: [{ required: true, message: '请填写姓名' }],
  gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
  nationality: [{ required: true, message: '请填写名族', trigger: 'change' }],
  idcard_num: [{ validator: idNumberValidator, trigger: 'change' }],
  address: [{ required: true, message: '请填写地址', trigger: 'change' }],
  starttime: [{ required: true, message: '请选择身份证开始日期', trigger: 'change', type: 'object' }],
  endtime: [{ required: true, validator: validateEnd, trigger: 'change' }],
  tel: [{ required: true, validator: mobileRequiredValidator, trigger: 'change' }],
  account: [{ required: true, message: '请填写姓名' }],
  password: [{ required: true, message: '请填写姓名' }]
}

interface state {
  modalTitle: string
  // 图片裁剪
  showCropper: boolean
  // 当前上传身份证的字段
  currentUpload: string
  modalWidth: number

  // 表格数据
  tableData: tableData[]
  // 进行裁剪的图片地址
  imageUrl: string
  imageName: string

  nationalityList: string[]
  loading_front: boolean
  loading_back: boolean
}
const state = reactive<state>({
  modalTitle: '新增人员',
  // 图片裁剪
  showCropper: false,
  // 当前上传身份证的字段
  currentUpload: '',
  modalWidth: 1000,

  // 表格数据
  tableData: [
    {
      // 法人名称
      name: null,
      // 性别
      gender: null,
      // 民族
      nationality: null,
      // 身份证号码
      idcard_num: null,
      // 地址
      address: null,
      // 是否为长期
      identity_perpetual: 2,
      // 身份证日期开始时间
      starttime: null,
      // 身份证日期截止时间
      endtime: null,
      // 身份证正面照片
      id_card_front: null,
      // 身份证反面照片
      id_card_back: null,
      account: null,
      password: null,
      // 手机号
      tel: null,
      // 编辑或者新增
      type: 'add'
    }
  ],
  // 进行裁剪的图片地址
  imageUrl: '',
  imageName: '',
  nationalityList: [
    '汉族',
    '蒙古族',
    '回族',
    '藏族',
    '维吾尔族',
    '苗族',
    '彝族',
    '壮族',
    '布依族',
    '朝鲜族',
    '满族',
    '侗族',
    '瑶族',
    '白族',
    '土家族',
    '哈尼族',
    '哈萨克族',
    '傣族',
    '黎族',
    '傈僳族',
    '佤族',
    '畲族',
    '高山族',
    '拉祜族',
    '水族',
    '东乡族',
    '纳西族',
    '景颇族',
    '柯尔克孜族',
    '土族',
    '达斡尔族',
    '仫佬族',
    '羌族',
    '布朗族',
    '撒拉族',
    '毛南族',
    '仡佬族',
    '锡伯族',
    '阿昌族',
    '普米族',
    '塔吉克族',
    '怒族',
    '乌孜别克族',
    '俄罗斯族',
    '鄂温克族',
    '德昂族',
    '保安族',
    '裕固族',
    '京族',
    '塔塔尔族',
    '独龙族',
    '鄂伦春族',
    '赫哲族',
    '门巴族',
    '珞巴族',
    '基诺族',
    '其他',
    '外国血统'
  ],
  loading_front: false,
  loading_back: false
})
onMounted(() => {
  // 编辑
  if (props.employmentData?.type == 'edit') {
    // 编辑直接通过核验

    let employmentData = cloneDeep(props.employmentData)
    if (employmentData.endtime) employmentData.endtime = dayjs(employmentData.endtime, format)
    if (employmentData.starttime) employmentData.starttime = dayjs(employmentData.starttime, format)

    state.modalTitle = '编辑人员'
    state.modalWidth = 1400
    state.tableData = [employmentData]
  }
})
const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  state.tableData[0][type] = str
}
const identityPerpetualChange = (val) => {
  state.tableData[0].identity_perpetual = val.target.checked ? 1 : 2
  personForm.value.validateFields('endtime')
}
// 提交
const onFormSubmit = () => {
  // 身份证信息验证
  if (!state.tableData[0].id_card_front || !state.tableData[0].id_card_back) {
    message.warning('请上传身份证正反面照片')
    return
  }
  personForm.value
    .validate()
    .then((valid) => {
      if (valid) {
        // 日期转换
        let data = cloneDeep(state.tableData[0])
        data.starttime = dayjs(data.endtime).format(format)
        data.endtime = dayjs(data.starttime).format(format)
        data.idcarda_img = state.tableData[0].id_card_front
        data.idcardb_img = state.tableData[0].id_card_back

        //提交到后台保存
        let requestParam = {
          url: '/admin/v1/dszAccountStore',
          method: 'post',
          data: data
        }
        baseService(requestParam)
          .then((res) => {
            message.success('新增成功')
            emit('change', res.data)
            emit('cancel')
          })
          .catch(() => {
            message.warning('请检查必填项')
          })
      }
    })
    .catch(() => {
      message.warning('请检查必填项')
    })
}
// 图片上传
const handleChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}

//上传裁剪后的图片
const cropperPost = (formData, isRecognize) => {
  //这个变量会在点另一个的时候变化，先记录一下再关闭窗口
  let currentUpload = state.currentUpload
  state.showCropper = false
  if (currentUpload == 'id_card_front') {
    state.loading_front = true
  } else if (currentUpload == 'id_card_back') {
    state.loading_back = true
  }
  if (isRecognize) {
    let requestParam = {
      url: '/admin/v1/ocr',
      method: 'post',
      data: formData
    }
    baseService(requestParam)
      .then((res) => {
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        }
        cropperChange(res, isRecognize, currentUpload)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        }
      })
  } else {
    let requestParam = {
      url: '/admin/v1/upload',
      method: 'post',
      data: formData
    }
    baseService(requestParam)
      .then((res) => {
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        }
        cropperChange(res.data, isRecognize, currentUpload)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        }
      })
  }
}
// 裁剪成功
const cropperChange = (res, isRecognize, currentUpload) => {
  if (isRecognize) {
    if (!res.data.身份证识别实体信息) {
      message.error('识别失败，请重新上传')
      return
    }
    let data = res.data.身份证识别实体信息
    if (currentUpload == 'id_card_back') {
      if (data.身份证国徽面实体信息.有效期限.length < 1) {
        message.error('请上传身份证国徽面照片')
        state.tableData[0].id_card_back = ''
        return
      }
      let list = data.身份证国徽面实体信息.有效期限.split('-').map((item, index) => {
        return item.replaceAll('.', '-')
      })
      let obj = {
        starttime: dayjs(list[0], format),
        endtime: dayjs(list[1], format),
        identity_perpetual: list[1] == '长期' ? 1 : 2
      }
      Object.assign(state.tableData[0], obj)
      personForm.value.validateFields('endtime')
    } else if (currentUpload == 'id_card_front') {
      if (data.身份证人像面实体信息.姓名.length < 1) {
        message.error('请上传身份证人像面照片')
        state.tableData[0].id_card_front = ''
        return
      }
      let nationality: any = null
      state.nationalityList.forEach((item) => {
        if (item.indexOf(data.身份证人像面实体信息.民族) > -1) {
          nationality = item
        }
      })
      let obj = {
        name: data.身份证人像面实体信息.姓名,
        address: data.身份证人像面实体信息.住址,
        idcard_num: data.身份证人像面实体信息.身份证号,
        gender: data.身份证人像面实体信息.性别 == '男' ? 1 : 2,
        nationality: nationality
      }
      Object.assign(state.tableData[0], obj)
    }
    state.tableData[0][currentUpload] = res.data.url
  } else {
    state.tableData[0][currentUpload] = res.url
  }
}
// 手动上传验证
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请上传正确图片！')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片必须小于2MB!')
  }
  return false
}
</script>

<style lang="less" scoped>
.information {
  display: flex;
  justify-content: center;
}

.information-right {
  display: flex;
  justify-content: center;
}

.information-card {
  margin-bottom: 20px;
  border-radius: 10px;

  :deep(.ant-card-head) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #40a9ff;
    color: #fff;
  }
}

.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 300px !important;
    height: 200px !important;
  }
}

.upload-img {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td) {
  padding-left: 28px;
}

.category {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }
}

.m-0 {
  :deep(.ant-form-explain) {
    position: absolute;
  }
}
</style>
