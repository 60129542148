import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { setupBeforeEach, setupAfterEach } from './routerGuard'
// import generateAsyncRoutes from './generateAsyncRoutes'
import { constantRouterMap } from '@/config/router.config'

const router = createRouter({
    history: createWebHistory(),
    routes: constantRouterMap as unknown as RouteRecordRaw[],
});
// 路由守卫,鉴权

setupBeforeEach(router)
setupAfterEach(router)

// TODO 手动获取路由
// if (config.useAsyncRouter) {
//     // 初次路由login时获取,然后存在ls,之后刷新页面时从本地获取,直接在初始化路由时就添加
//     generateAsyncRoutes(router)
// }

// 在需要重置路由的时候调用这个函数
export function resetRouter(router) {
    //先删掉原来所有的路由
    router.getRoutes().forEach(route => {
        const { name } = route;
        if (name) {
            router.hasRoute(name) && router.removeRoute(name);
        }
    });
    //再把基础的加上
    constantRouterMap.forEach(route => {
        router.addRoute(route);
    });
}

export default router;
