import ls from '@/utils/Storage'
/**
 * 是否是当前登录人
 * 2024-12-02
 */
export default {
    mounted(el, binding, vnode) {
        const { value } = binding
        const user = ls.get('USER_INFO')

        if (value && value instanceof Array && value.length > 0) {
            const permissionFlag = value
            const hasPermissions = user.id == 1 || permissionFlag.includes(user.id)
            if (!hasPermissions) {
                //el.parentNode && el.parentNode.removeChild(el)
                el.style.display = 'none';
            }
        } else {
            throw new Error(`请设置操作用户标签值`)
        }
    }
}
