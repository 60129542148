<template>
  <a-modal
    title="工单详情"
    width="80%"
    :open="true"
    :confirmLoading="state.loading"
    @cancel="
      () => {
        emit('cancel')
      }
    "
    :footer="false"
  >
    <a-watermark :content="state.allperson" :gap="[200, 200]" fontWeight="light">
      <a-skeleton :loading="state.loading" active :paragraph="{ rows: 4 }">
        <div class="p-1x">
          <a-descriptions size="small" :column="2" :bordered="true">
            <a-descriptions-item label="工单标题">
              <p>
                {{ props.tableColumnsData.title }}
                <a-tag color="red" v-if="props.tableColumnsData.type == 1">投诉</a-tag>
                <a-tag color="orange" v-else-if="props.tableColumnsData.type == 2">举报</a-tag>
                <a-tag color="green" v-else>代办事项</a-tag>
              </p>
            </a-descriptions-item>
            <a-descriptions-item label="关联主体：">
              <a @click="goCustomerClick(props.tableColumnsData.full_name)" class="pointer"> {{ props.tableColumnsData.full_name }}</a>
            </a-descriptions-item>
            <a-descriptions-item label="工单状态">
              <a-tag color="green">{{
                props.tableColumnsData.status === 0 || props.tableColumnsData.status === '0'
                  ? '待处理'
                  : props.tableColumnsData.status === 1
                  ? '已办结'
                  : props.tableColumnsData.status === 2
                  ? '已取消'
                  : '已删除'
              }}</a-tag>
            </a-descriptions-item>
            <a-descriptions-item label="工单截止时间">
              {{ props.tableColumnsData.start_time }} ~
              {{ props.tableColumnsData.end_time }}
            </a-descriptions-item>

            <a-descriptions-item label="工单处理人">
              <a-tag color="green" class="mr-1x mb-1x" v-for="(item, index) in props.tableColumnsData.reception_admin_info"> {{ item.nickname }} {{ item.phone }} </a-tag>
            </a-descriptions-item>

            <a-descriptions-item label="工单监督人">
              <a-tag color="green" class="mr-1x mb-1x" v-for="(item, index) in props.tableColumnsData.custodian_admin_info"> {{ item.nickname }} {{ item.phone }} </a-tag>
            </a-descriptions-item>

            <a-descriptions-item label="工单内容" :span="3" style="width: 105px">
              {{ props.tableColumnsData.content }}
            </a-descriptions-item>

            <a-descriptions-item label="工单附件" :span="3">
              <span v-for="(item, index) in props.tableColumnsData.attachment">
                <a-image
                  v-if="item.name.indexOf('jpg') > -1 || item.name.indexOf('png') > -1 || item.name.indexOf('jpeg') > -1"
                  :key="index"
                  :width="100"
                  :height="100"
                  :src="$store.state.user.image_domain + item.url"
                >
                </a-image>
                <a v-else :href="$store.state.user.image_domain_a + item.url" download target="_blank">{{ item.name }}</a>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="最新动态" :span="3">
              <div class="mv-2x" v-if="state.work_order_chat?.length > 0" style="width: 35%; float: left">
                <div style="height: 6px"></div>
                <a-timeline class="timelineStatus">
                  <a-timeline-item color="green" v-for="(item, index) in state.work_order_chat" :key="index">
                    <p>{{ item.after_status_name }} {{ item.created_at }}</p>
                    <p v-html="item.msg"></p>
                    <template v-if="item.is_read == 0">
                      <a-badge :dot="true"></a-badge>
                    </template>
                    <div v-if="item.attachment">
                      <span v-for="(item, index) in item.attachment">
                        <a-image
                          v-if="item.name.indexOf('jpg') > -1 || item.name.indexOf('png') > -1 || item.name.indexOf('jpeg') > -1"
                          :key="index"
                          :width="120"
                          :height="50"
                          :src="$store.state.user.image_domain + item.url"
                        >
                        </a-image>
                        <p v-else>
                          <a :href="$store.state.user.image_domain + item.url" target="_blank">{{ item.name }}</a>
                        </p>
                      </span>
                    </div>
                    <p>发送人：{{ item.send_info.nickname }}</p>
                  </a-timeline-item>
                </a-timeline>
              </div>
              <a-empty v-if="state.work_order_chat?.length == 0" style="height: 300px" description="暂无微信沟通" class="flex-center flex-dc" />
              <div style="width: 65%; float: left" class="mv-2x">
                <a-form ref="formRef" v-bind="formLayout">
                  <a-form-item label="附件上传">
                    <Upload
                      upload_path="public/uploads/工单回复"
                      :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
                      v-model:value="state.attachment"
                      uploadType="text"
                      :limitation="9"
                      uploadText="上传附件"
                    >
                    </Upload>
                  </a-form-item>
                  <a-form-item label="发布内容">
                    <div style="display: flex">
                      <a-input v-model:value="state.content" type="textarea" placeholder="请输入发布内容"> </a-input>
                      <a-popconfirm title="确定发布吗?" @confirm="submit">
                        <a-button class="mr-1x" type="primary">发布</a-button>
                      </a-popconfirm>
                    </div>
                  </a-form-item>

                  <a-form-item :wrapper-col="{ span: 18, offset: 7 }">
                    <a-popconfirm title="确定办结此工单?" @confirm="changeStatus(1)">
                      <a-button class="mr-1x" type="primary">办结工单</a-button>
                    </a-popconfirm>

                    <a-popconfirm title="确定取消此工单?" @confirm="changeStatus(2)">
                      <a-button class="mr-1x" type="primary">取消工单</a-button>
                    </a-popconfirm>

                    <a-popconfirm title="确定恢复待处理?" @confirm="changeStatus(0)">
                      <a-button class="mr-1x" type="primary">恢复待处理</a-button>
                    </a-popconfirm>
                  </a-form-item>
                </a-form>
              </div>
            </a-descriptions-item>
          </a-descriptions>

          <!-- <a-button class="mr-1x" type="primary" @click="changeStatus(1)">办结工单</a-button>
        <a-button class="mr-1x" type="primary" @click="changeStatus(2)">取消工单</a-button> -->
          <!-- <a-button class="mr-1x" @click="onTransferOrderClick" type="primary">转单</a-button> -->
          <transfer-order
            v-if="state.transferOrderVisible"
            ref="transferOrderRef"
            @cancel="
              () => {
                state.transferOrderVisible = false
              }
            "
          ></transfer-order>
        </div>
      </a-skeleton>
    </a-watermark>
  </a-modal>
</template>

<script lang="ts" setup>
import cloneDeep from 'lodash.clonedeep'
import transferOrder from './transferOrder.vue'
import { ref, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import Upload from '@/components/base/Upload.vue'
const $store = useStore()
import baseService from '@/utils/http/axios'
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => null
  }
})
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}

onMounted(() => {
  queryDetail()
})
const formRef = ref()
const emit = defineEmits(['cancel', 'ok'])
const state = reactive<any>({
  transferOrderVisible: false,
  loading: false,
  content: null,
  attachment: [],
  // 上传文件
  fileList: [],
  detailData: {},
  fileInformation: [],
  work_order_chat: [],
  allperson: ''
})
const goCustomerClick = (name) => {
  emit('cancel')
  router.push({ path: '/customer', query: { customer_name: name, tabsKey: 2 } })
}
const queryDetail = () => {
  state.loading = true
  let requestParam = {
    url: `/admin/v1/workOrderChatLog?id=` + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      state.work_order_chat = res.data.work_order_chat
      state.allperson = allperson(props.tableColumnsData.reception_admin_info)
    })
    .catch((res) => {
      state.loading = false
    })
}
const allperson = (p) => {
  let text = ''
  for (let itemindex in p) {
    text += p[itemindex].nickname + '&&'
  }
  return text.substring(0, text.length - 2)
}
const onTransferOrderOk = () => {
  state.transferOrderVisible = false
  emit('ok')
}

const changeStatus = (type) => {
  let requestParam = {
    url: '/admin/v1/workOrder/changeStatus',
    method: 'post',
    data: {
      id: props.tableColumnsData.id,
      status: type
    }
  }
  baseService(requestParam)
    .then((res) => {
      message.success(res.msg ? res.msg : '操作成功')
      emit('ok')
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '操作失败')
    })
}

const submit = () => {
  if (!state.content && !state.attachment) {
    message.error('回复内容或附件不能为空')
    return
  }
  let requestParam = {
    url: '/admin/v1/workOrder/reply',
    method: 'post',
    data: {
      work_order_id: props.tableColumnsData.id,
      msg: state.content,
      attachment: state.attachment
    }
  }
  baseService(requestParam)
    .then((res) => {
      message.success(res.msg ? res.msg : '操作成功')
      state.content = null
      state.attachment = []
      queryDetail()
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '操作失败')
    })
}
const onTransferOrderClick = () => {
  state.transferOrderVisible = true
}
</script>

<style lang="less" scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.add-button {
  position: absolute;
  right: 65px;
  top: 10px;
}

.positionItem {
  display: inline-block;
  margin-bottom: 10px;
}
.timelineStatus {
  .ant-timeline-item:last-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
</style>
