<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="主体名称" name="nameOrCode">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入主体名称、税号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="注销状态" name="status">
              <a-select mode="multiple" v-model:value="queryParam.status" placeholder="请选择">
                <a-select-option :value="1">已录单</a-select-option>
                <a-select-option :value="2">已简易公示</a-select-option>
                <a-select-option :value="3">已一般注销公示</a-select-option>
                <a-select-option :value="4">公示已到期</a-select-option>
                <a-select-option :value="5">待签名</a-select-option>
                <a-select-option :value="6">签名已完成待提交</a-select-option>
                <a-select-option :value="7">等待审核结果</a-select-option>
                <a-select-option :value="8">退回待修改</a-select-option>
                <a-select-option :value="9">未通过审核</a-select-option>
                <a-select-option :value="10">审核通过</a-select-option>
                <a-select-option :value="11">已办结</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="主体类型" name="main_type">
              <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择">
                <a-select-option :value="1">公司</a-select-option>
                <a-select-option :value="2">个体户</a-select-option>
                <a-select-option :value="3">个独</a-select-option>
                <a-select-option :value="4">合伙</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="代办人" name="agent_idcard_name">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.agent_idcard_name" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="注销类型" name="logout_type">
              <a-select mode="multiple" v-model:value="queryParam.logout_type" placeholder="请选择">
                <a-select-option :value="1">简易注销</a-select-option>
                <a-select-option :value="2">一般注销</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间" name="between_time">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="查询已删除主体" name="recycle">
              <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="queryParam.recycle"></a-switch>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="table-page-search-submitButtons" style="overflow: hidden; margin-bottom: 0">
              <a-button type="primary" @click="loadData">查询</a-button>
            </div>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="navlist">
              <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd">新建注销主体</a-button>
              <a-button type="primary" @click="onActionClick({}, 'SignatureStatus')">查询客户签字状态</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table rowClassName="tableRowClass" :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'full_name'">
          <div class="companyInformation">
            <div class="mv-1x">
              <a-tag color="green" v-if="record.logout_type == 1">简易注销</a-tag>
              <a-tag color="pink" v-if="record.logout_type == 2">一般注销</a-tag>
              <a-popover trigger="click" v-model:open="record.isShowCompany" overlayClassName="customer-options-popover">
                <template #content>
                  <p @click="handleDoubleNameClick(record.full_name, 1)">跳转到天眼查</p>
                  <p @click="handleDoubleNameClick(record.full_name, 2)">跳转到企查查</p>
                </template>
                <div class="mr-1x pointer"></div>
              </a-popover>
              <span
                class="mr-1x pointer"
                @click="
                  (e) => {
                    record.isShowCompany = false
                    handleNameClick(e, record.full_name)
                  }
                "
                @dblclick="
                  () => {
                    record.isShowCompany = true
                  }
                "
                v-html="record.fullName"
              ></span>
            </div>
            <div class="mb-x pointer">
              <span
                @dblclick="onTaxInquiryClick(record, 3)"
                @click="
                  (e) => {
                    handleNameClick(e, record.social_credit_code)
                  }
                "
                >{{ record.social_credit_code }}</span
              >
            </div>
            <div class="mb-x" v-if="record.logout_sqtype == 1">{{ record.liaison_identity ? record.liaison_identity : '未填写' }}</div>
            <div class="mb-x">
              <a-tag color="blue">{{ record.logout_sqtype == 1 ? '收验证码' : '电子执照授权' }}</a-tag>
              <a-tag color="blue"> {{ record.areaName }}</a-tag>
            </div>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'status'">
          <div class="statuslist">
            【{{ record.order_status?.after_status_name }}<span v-if="record.deleted_at" style="color: red">-已删除</span>】 <a @click="onViewLogClick(record, 1)">查看更多</a>
          </div>
          <p class="mb-x">状态改变时间：{{ record.order_status?.created_at }}</p>
          <p class="mb-x">上次查询时间：{{ record.order_status?.updated_at }}</p>
          <div class="mb-x" v-if="record.status == 5"><a @click="onActionClick(record, 'SignatureStatus')">查询客户签字状态</a></div>
        </template>
        <template v-else-if="column.dataIndex === 'appFlog_sumType'" style="border: 3px dashed #ccc">
          <!-- 第一步：处理营业执照（公司+个体通用） -->
          <div class="mb-x">
            <div class="mb-x" v-if="record.appFlog_sumType == 1">
              <span>第一步：到登记窗口提交 </span>
              <a-tag color="green">已完成</a-tag>
              <a-image v-for="(item, idx) in record.business_license_and_copy" :key="idx" :width="20" :src="$store.state.user.image_domain + item" class="giveborder" />
            </div>
            <div class="mb-x" v-if="record.appFlog_sumType == 3">
              <span>第一步：执照遗失或损坏公告作废 </span>
              <span v-if="record.business_license_and_copy.length == 0">
                <a-tag color="error">
                  <template #icon> <CloseCircleOutlined /> </template>未完成</a-tag
                >
                <a-button type="dashed" danger size="small" :loading="record.zfloading" @click="zuofei(record)">直接操作作废</a-button>
              </span>
              <span v-else>
                <a-tag color="success">
                  <template #icon> <CheckCircleOutlined /> </template>已完成</a-tag
                >
                <a-image v-for="(item, idx) in record.business_license_and_copy" :key="idx" :width="20" :src="$store.state.user.image_domain + item" class="giveborder" />
              </span>
            </div>
          </div>
          <!-- 公司的流程 -->
          <div class="mb-x" v-if="record.main_type == 1">
            <!-- 第二步：注销公示-公司才有 -->
            <div class="mb-x" v-if="!(record.appFlog_sumType == 3 && record.business_license_and_copy.length == 0)">
              <span>第二步：全体投资人承诺书公示</span>
              <span v-if="!record.push_proof && record.status == 1">
                <a-tag color="error">
                  <template #icon> <CloseCircleOutlined /> </template>未完成</a-tag
                >
                <a-button type="dashed" danger size="small" :loading="record.gsloading" @click="gongshi(record)">直接操作公示</a-button>
              </span>
              <span v-else>
                <a-tag color="success">
                  <template #icon> <CheckCircleOutlined /> </template>已完成</a-tag
                ><a-image :width="20" :src="$store.state.user.image_domain + record.push_proof" class="giveborder"
              /></span>
              <div class="mb-x" v-if="record.push_proof">
                <span>公式日期：{{ record.publicity_time }}~{{ record.publicity_time_end }}</span>
              </div>
            </div>
            <!-- 第三步：公示到期需要推送做单 -->
            <div class="mb-x" v-if="record.status >= 4">
              <span>第三步：公示到期，可以正式注销了</span>
              <span v-if="!record.cancel_proof && record.status == 4">
                <a-tag color="error">
                  <template #icon> <CloseCircleOutlined /> </template>未完成</a-tag
                >
                <a-button type="dashed" danger size="small" :loading="record.zxloading" @click="zhuxiao(record)">直接操作注销</a-button>
              </span>
              <span v-else>
                <a-tag color="success">
                  <template #icon> <CheckCircleOutlined /> </template>已完成</a-tag
                ><a-image :width="20" :src="$store.state.user.image_domain + record.cancel_proof" class="giveborder"
              /></span>
            </div>
            <!-- 第四步：签名后注销确认 -->
            <div class="mb-x">
              <div class="mb-x" v-if="record.status == 6">
                <span>第四步：签名完成待提交</span>
                <a-tag color="error">
                  <template #icon> <CloseCircleOutlined /> </template>未完成</a-tag
                ><a-button type="dashed" danger size="small">直接操作提交</a-button>
              </div>
            </div>
            <!-- 第五步: 审核 -->
            <div class="mb-x">
              <div class="mb-x" v-if="record.status == 8 || record.status == 9 || record.status == 10">
                <span>第五步：审核结果</span>
                <a-button type="dashed" danger size="small">查看审核结果</a-button>
              </div>
            </div>
          </div>
          <!-- 个体户的流程 -->
          <div class="mb-x" v-if="record.main_type == 2">
            <!-- 第二步：公示到期需要推送做单 -->
            <div class="mb-x">
              <div class="mb-x" v-if="!(record.appFlog_sumType == 3 && record.business_license_and_copy.length == 0)">
                <span>第二步：已录单，可以正式注销了</span>
                <span v-if="!record.cancel_proof && record.status == 1">
                  <a-tag color="error">
                    <template #icon> <CloseCircleOutlined /> </template>未完成</a-tag
                  >
                  <a-button type="dashed" danger size="small" :loading="record.zxloading" @click="zhuxiao(record)">直接操作注销</a-button>
                </span>
                <span v-else>
                  <a-tag color="success">
                    <template #icon> <CheckCircleOutlined /> </template>已完成</a-tag
                  ><a-image :width="20" :src="$store.state.user.image_domain + record.cancel_proof" class="giveborder"
                /></span>
              </div>
            </div>
            <!-- 第三步：签名后注销确认 -->
            <div class="mb-x">
              <div class="mb-x" v-if="record.status == 6">
                <span>第三步：签名完成待提交</span>
                <a-tag color="error">
                  <template #icon> <CloseCircleOutlined /> </template>未完成</a-tag
                ><a-button type="dashed" danger size="small">直接操作提交</a-button>
              </div>
            </div>
            <!-- 第四步: 审核 -->
            <div class="mb-x">
              <div class="mb-x" v-if="record.status == 8 || record.status == 9 || record.status == 10">
                <span>第四步：审核结果</span>
                <a-button type="dashed" danger size="small">查看审核结果</a-button>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'sellerid'">
          <p class="mb-x">代理商：{{ record.sellerName ? record.sellerName : '-' }}</p>
          <span>{{ record.created_at }}</span>
        </template>
        <template v-else-if="column.dataIndex === 'action'">
          <div class="mb-x">
            <p>
              <a-button type="primary" size="small" @click="onTZZLYCheck(record)">通知资料员做单</a-button>
            </p>
            <p>
              <a @click="handleEdit(record)">编辑 </a>
              <a-divider type="vertical" />
              <a-popover trigger="click" overlayClassName="customer-options-popover">
                <template #content>
                  <div v-for="(item, index) in record.DownType" :key="index" @click="downLease(item)">
                    <p>{{ item.name }}</p>
                  </div>
                </template>
                <a>下载</a>
              </a-popover>
              <a-divider type="vertical" />
              <a-popconfirm title="确定删除此注销单?" @confirm="handleDelete(record)">
                <a style="color: red">删除</a>
              </a-popconfirm>
            </p>
          </div>
        </template>
      </template>
    </a-table>
    <!-- 查看日志 -->
    <view-log :logTabsKey="data.logTabsKey" v-if="data.visibleLog" :tableColumnsData="data.tableColumnsData" @cancel="viewLogCancel"></view-log>
    <!-- 登录授权：公示 -->
    <login-authorisation :tableColumnsData="data.tableColumnsData" v-if="data.authorisationVisible" @cancel="handleCancel" @ok="handleAuthorisationOk"></login-authorisation>
    <!-- 登录授权:登记 -->
    <login-verification :tableColumnsData="data.tableColumnsData" v-if="data.verificationVisible" @cancel="handleCancel" @ok="handleVerificationOk"></login-verification>
    <!-- 查看签名状态 -->
    <SignatureStatus
      :tableColumnsData="data.tableColumnsData"
      v-if="data.signatureStatusVisible"
      @cancel="
        () => {
          data.signatureStatusVisible = false
        }
      "
    ></SignatureStatus>
    <!-- 新增客户 -->
    <create-form ref="createForm" :key="data.createKey" :open="data.visible" @cancel="handleCancel" @ok="handleOk" />
    <edit-form v-if="data.editVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
  </a-card>
</template>

<script lang="ts" setup name="CustomerList">
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import CreateForm from './CreateForm.vue'
import EditForm from './CreateForm.vue'
import ViewLog from './components/ViewLog.vue'
import LoginAuthorisation from './components/LoginAuthorisation.vue'
import LoginVerification from './components/LoginVerification.vue'
import SignatureStatus from './components/SignatureStatus.vue'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { costByPoints } from '@logicflow/core/types/util'
import { useStore } from 'vuex'
const $store = useStore()
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'

interface data {
  directPaymentVisible: boolean

  pushPaymentVisible: boolean

  visibleLog: boolean //log弹窗的显示
  tableColumnsData: Object //传数据的
  logTabsKey: number //log选项卡的序号

  optionsVisible: boolean
  visible: boolean
  advanced: boolean
  loading: boolean //表格的加载中
  editVisible: boolean //编辑弹窗
  authorisationVisible: boolean //登录授权
  verificationVisible: boolean
  signatureStatusVisible: boolean
  refundVisible: boolean
  exportLoading: boolean
  createKey: number //添加的key
  downList: any
}
const data = reactive<data>({
  exportLoading: false,

  // 付款弹窗
  directPaymentVisible: false,

  // 推送付款
  pushPaymentVisible: false,

  visibleLog: false,

  // 操作弹窗
  optionsVisible: false,
  // create model
  visible: false,
  // 高级搜索 展开/关闭
  advanced: false,
  tableColumnsData: {},
  loading: false,
  // 编辑
  editVisible: false,
  //登录授权
  authorisationVisible: false,
  verificationVisible: false,
  // 查看签名状态
  signatureStatusVisible: false,
  refundVisible: false,
  // 进入日志 还是进入状态
  logTabsKey: 1,
  createKey: 1,
  /* 下载列表 */
  downList: {}
})
const filteredInfo = ref()
const createForm = ref()

const columns = computed(() => {
  const filtered = filteredInfo.value || []
  return [
    {
      title: '公司名称',
      dataIndex: 'full_name',
      width: 350
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      width: 350
    },

    {
      title: '注销控制台',
      dataIndex: 'appFlog_sumType',
      width: 480
    },

    {
      title: '其他信息',
      dataIndex: 'sellerid',
      width: 240
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 240
    }
  ]
})
const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
onActivated(() => {
  queryParam.nameOrCode = router.currentRoute.value.query.customer_name
  if (router.currentRoute?.value?.query?.customer_name) {
    loadData()
  }
})

const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/simpleLogout/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}
// 复制名称
const handleNameClick = (e, name) => {
  if (e.detail == 1) {
    var input = document.createElement('input')
    //将input的值设置为需要复制的内容
    input.value = name
    //添加input标签
    document.body.appendChild(input)
    //选中input标签
    input.select()
    //执行复制
    document.execCommand('copy')
    //成功提示信息
    message.success('复制成功，尝试双击有惊喜！')
    //移除input标签
    document.body.removeChild(input)
  }
}

// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/simpleLogoutListAll',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, customer_id: item.id }
      })
      let downList: any = []
      for (const key in res.data.down_type) {
        downList.push({ type: key, name: res.data.down_type[key] })
      }
      data.downList = downList
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}

// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}

//右上角的查询客户签字状态
const onActionClick = (record, type) => {
  record.isShowOptions = false
  data.tableColumnsData = { ...record }
  switch (type) {
    case 'SignatureStatus':
      data.signatureStatusVisible = true
      break
    case 'Refund':
      data.refundVisible = true
      break
  }
}

//右上角的添加和编辑
const handleAdd = () => {
  data.tableColumnsData = {}
  data.visible = true
}

const handleEdit = (record) => {
  data.editVisible = true
  data.tableColumnsData = { ...record }
}

const handleAuthorisationOk = (...record) => {
  data.createKey++
  data.visible = false
  data.refundVisible = false
  data.editVisible = false
  data.authorisationVisible = false
  data.verificationVisible = false
  data.directPaymentVisible = false
  //操作具体内容
  if (record[1] == 1) {
    //遗失的验证码回来
    dataSource.value.forEach((item: any) => {
      if (item.id == record[0]) {
        zuofeiOneItem(item)
      }
    })
  } else if (record[1] == 2) {
    //简易注销公示的回来
    dataSource.value.forEach((item: any) => {
      if (item.id == record[0]) {
        gongshiOneItem(item)
      }
    })
  }
}

const handleVerificationOk = (...record) => {
  data.createKey++
  data.visible = false
  data.refundVisible = false
  data.editVisible = false
  data.authorisationVisible = false
  data.verificationVisible = false
  data.directPaymentVisible = false
  //操作具体内容
  if (record[1] == 1) {
    //注销登记的验证码回来
    dataSource.value.forEach((item: any) => {
      if (item.id == record[0]) {
        zhuxiaoOneItem(item)
      }
    })
  }
  //   else if (record[1] == 2) {
  //     //登记提交的回来
  //     dataSource.value.forEach((item: any) => {
  //       if (item.id == record[0]) {
  //         gongshiOneItem(item)
  //       }
  //     })
  //   }
}

//作废
const zuofeiOneItem = (record) => {
  // 操作转圈
  record.zfloading = true
  let interval //定时器
  const hide = message.loading({
    content: '操作中,请稍等',
    key: 'zuofeiKey',
    class: 'loading-message'
  })
  //发请求去后端    console.log(record)
  let requestParam = {
    url: '/admin/v1/cancellationZuofei?id=' + record.id,
    method: 'POST'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success({ content: '营业执照作废执行中,请稍后', key: 'zuofeiKey', duration: 2 })
    })
    .catch((res) => {
      record.zfloading = false
      clearInterval(interval)
      message.error({ content: res.msg ? res.msg : '营业执照作废执行失败', key: 'zuofeiKey', duration: 2 })
    })

  //监听python是否作废完成（作废的图片存到了数据库）
  interval = setInterval(() => {
    let requestParam = {
      url: `/admin/v1/cancellationZuofeiCheck`,
      method: 'POST',
      data: { id: record.id }
    }
    baseService(requestParam)
      .then((res) => {
        if (res.msg == 'ok') {
          record.zfloading = false
          record.status = res.data.status
          record.business_license_and_copy = [...res.data.business_license_and_copy]
          clearInterval(interval)
        }
      })
      .catch((res) => {})
  }, 2000)
}
//公示
const gongshiOneItem = (record) => {
  // 操作转圈
  record.gsloading = true
  let interval //定时器
  const hide = message.loading({
    content: '操作中,请稍等',
    key: 'gongshiKey',
    class: 'loading-message'
  })
  //发请求去后端    console.log(record)
  let requestParam = {
    url: '/admin/v1/cancellationGongshi?id=' + record.id,
    method: 'POST'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success({ content: '注销公示执行中,请稍后', key: 'gongshiKey', duration: 2 })
    })
    .catch((res) => {
      record.gsloading = false
      clearInterval(interval)
      message.error({ content: res.msg ? res.msg : '注销公示执行失败', key: 'gongshiKey', duration: 2 })
    })
  //监听python是否作废完成（作废的图片存到了数据库）
  interval = setInterval(() => {
    let requestParam = {
      url: `/admin/v1/cancellationGongshiCheck`,
      method: 'POST',
      data: { id: record.id }
    }
    baseService(requestParam)
      .then((res) => {
        if (res.msg == 'ok') {
          record.gsloading = false
          record.status = res.data.status
          record.push_proof = res.data.push_proof
          clearInterval(interval)
        }
      })
      .catch((res) => {})
  }, 2000)
}
//注销登记
const zhuxiaoOneItem = (record: any) => {
  // 操作转圈
  record.zxloading = true
  let interval //定时器
  const hide = message.loading({
    content: '操作中,请稍等',
    key: 'zhuxiaoKey',
    class: 'loading-message'
  })
  //发请求去后端    console.log(record)
  let requestParam = {
    url: '/admin/v1/cancellationZhuxiao?id=' + record.id,
    method: 'POST'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success({ content: '注销执行中,请稍后', key: 'zhuxiaoKey', duration: 2 })
    })
    .catch((res) => {
      record.zxloading = false
      clearInterval(interval)
      message.error({ content: res.msg ? res.msg : '注销执行失败', key: 'zhuxiaoKey', duration: 2 })
    })
  //监听python是否作废完成（作废的图片存到了数据库）
  interval = setInterval(() => {
    let requestParam = {
      url: `/admin/v1/cancellationZhuxiaoCheck?id=` + record.id,
      method: 'get'
    }
    baseService(requestParam)
      .then((res: any) => {
        if (res.msg == 'ok') {
          record.zxloading = false
          record.status = res.data.status
          record.cancel_proof = res.data.cancel_proof
          clearInterval(interval)
        }
      })
      .catch((res) => {})
  }, 2000)
}

const handleOk = () => {
  data.createKey++
  data.visible = false
  data.refundVisible = false
  data.editVisible = false
  data.authorisationVisible = false
  data.verificationVisible = false
  data.directPaymentVisible = false
  loadData()
}
const handleCancel = () => {
  data.visible = false
  data.editVisible = false
  data.authorisationVisible = false
  data.verificationVisible = false
  data.refundVisible = false
  data.directPaymentVisible = false
}

//通知资料员做单
const onTZZLYCheck = (record) => {
  //判断营业执照遗失是否做完
  if (record.appFlog_sumType == 3 && record.business_license_and_copy.length == 0) {
    message.error('您选择的营业执照提交方式为：遗失声明，请操作按钮左侧的第一步：直接操作作废！')
    return
  }
  if (record.main_type == 1) {
    //公司流程
    if (record.main_type == 1 && record.status == 1) {
      message.error('全体投资人承诺书公示未完成，请直接公示！')
      return
    }
    if (record.status == 2 || record.status == 3) {
      message.error('公示未到期，请耐心等待！')
      return
    }
    if (record.status == 4) {
      //注销登记
      zhuxiao(record)
    }
  } else if (record.main_type == 2) {
    //个体流程
    if (record.status == 1) {
      //注销登记
      zhuxiao(record)
    }
  }
}

// 查询日志
const onViewLogClick = (record, val) => {
  data.visibleLog = true
  data.tableColumnsData = { ...record }
  data.logTabsKey = val
}
const viewLogCancel = (isRefresh) => {
  if (isRefresh) {
    loadData()
  }
  data.visibleLog = false
}

//营业执照作废
const zuofei = (record) => {
  // 弹出填验证码的框
  data.tableColumnsData = { ...record }
  data.tableColumnsData['steptype'] = 1 //标记是作废
  data.authorisationVisible = true
}

//注销公示
const gongshi = (record) => {
  // 弹出填验证码的框
  data.tableColumnsData = { ...record }
  data.tableColumnsData['steptype'] = 2 //标记是公示
  data.authorisationVisible = true
}
//注销登记
const zhuxiao = (record) => {
  data.tableColumnsData = { ...record }
  data.tableColumnsData['steptype'] = 1 //标记是登记
  data.verificationVisible = true
}
// 跳转天眼查
const handleDoubleNameClick = (name, type) => {
  if (type == 1) {
    window.open('https://www.tianyancha.com/nsearch?key=' + name)
  } else if (type == 2) {
    window.open('https://www.qcc.com/web/search?key=' + name)
  }
}
// 跳转到税务局
const onTaxInquiryClick = (record, type) => {
  let param = `?${record.social_credit_code ? 'data-creditcode=' + record.social_credit_code + '&' : ''}data-companyname=${record.full_name}&type=1`
  if (type == 1) {
    window.open('http://hunan.chinatax.gov.cn/taxpayerstatesearch/20190413003982' + param)
  } else if (type == 2) {
    window.open('http://hunan.chinatax.gov.cn/taxpayersearch/20190413003981' + param)
  } else if (type == 3) {
    window.open('http://hunan.chinatax.gov.cn/taxreginfosearch/20190413003984' + param)
  }
}

const downLease = (downItem) => {
  if (downItem?.children?.length > 0) {
    return
  }
  let url = downItem.url.replace('https://console.cskaiyela.com/hncskaiyela', '')
  const hide = message.loading({
    content: '等待下载中,请稍等',
    duration: 0,
    class: 'loading-message'
  })
  let requestParam = {
    url: url,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = ''
      a.href = downItem.url + `&token=${token}&down=1`
      a.dispatchEvent(event)
      setTimeout(hide, 2000)
    })
    .catch((res) => {
      setTimeout(hide, 500)
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败,请联系管理员')
      }
    })
}
</script>
<style lang="less" scoped>
.navlist {
  button {
    margin-right: 10px;
  }
}

.tabs {
  position: relative;
  .tabs-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 0;
    display: flex;
    gap: 32px;
    align-items: center;
  }
}
:deep(.tableRowClass) {
  .ant-table-cell:first-child {
    padding: 0 !important;
  }
}
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px !important;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}

.companyInformation {
  padding: 16px;
  .deng {
    display: none;
  }
}

.companyInformation:hover {
  .deng {
    display: block;
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
:deep(.giveborder) {
  border: 2px dashed #ccc;
  padding: 1px;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}
</style>
