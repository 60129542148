<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createMenu',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    width="60%"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="用户名" name="username">
          {{ props.detailData.nickname }}
        </a-form-item>
        <a-form-item label="除角色权限外，额外给予菜单权限" name="permissions">
          <div style="line-height: 32px">
            <a-checkbox v-model:checked="state.unfold" @change="unfoldChange">展开/折叠</a-checkbox>
            <a-checkbox v-model:checked="state.checkAll" @change="checkAllChange">全选/全不选</a-checkbox>
          </div>
          <div class="tree">
            <a-tree v-model:expandedKeys="state.expandedKeys" v-model:checkedKeys="formState.permissions" checkable :tree-data="state.permissionList" :fieldNames="fieldNames">
              <!-- <template #title="{ display_name, id }">
                                <template>{{ display_name }}</template>
                            </template> -->
            </a-tree>
          </div>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createMenu">
import { reactive, ref, onMounted, computed, watch, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import type { TreeProps } from 'ant-design-vue'
import dayjs from 'dayjs'
const format = 'YYYY-MM-DD HH:mm:ss'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]

const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
  //   permissions: [{ required: true, message: '请选择菜单权限', trigger: 'change' }]//可以不选
}
const fieldNames = {
  title: 'display_name',
  key: 'id',
  children: '_child'
}
const unfoldChange = (val: any) => {
  if (val.target.checked) {
    state.expandedKeys = state.permissionList.map((item) => item.id)
  } else {
    state.expandedKeys = []
  }
}
const checkAllChange = (val: any) => {
  if (val.target.checked) {
    formState.permissions = state.permissionList.map((item) => item.id)
  } else {
    formState.permissions = []
  }
}
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
  unfold: boolean
  checkAll: boolean
  permissionList: any
  expandedKeys: (string | number)[]
}
const state = reactive<state>({
  modalTitle: '编辑用户菜单权限',
  loading: false,
  unfold: false,
  checkAll: false,
  permissionList: [],
  expandedKeys: []
})
interface formState {
  permissions: (string | number)[]
}
let formState = reactive<formState>({
  permissions: []
})

watch(
  () => formState.permissions,
  (val) => {
    nextTick(() => {
      ruleForm.value.validate('permissions')
    })
  }
)
onMounted(() => {
  queryPermissions()
})

const queryPermissions = () => {
  state.loading = true
  let requeryParam = {
    url: `/admin/role/create`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.permissionList = res.data.permissions
    if (props.detailData?.id) {
      queryDetail()
      return
    }
    state.loading = false
  })
}
const queryDetail = () => {
  let requeryParam = {
    url: `/admin/user/menuSet`,
    method: 'post',
    data: { userId: props.detailData.id }
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    formState.permissions = res.data.permissions_ids
  })
}

const onFormSubmit = () => {
  ruleForm.value.validate().then(() => {
    state.loading = true
    let requeryParam = {
      url: `/admin/user/menuSetSave`,
      method: 'post',
      data: Object.assign(formState, { userId: props.detailData.id })
    }
    baseService(requeryParam)
      .then((res: any) => {
        state.loading = false
        message.success('操作成功')
        emit('cancel')
      })
      .catch((res) => {
        state.loading = false
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  })
}
</script>

<style lang="less" scoped>
.tree {
  margin-top: 10px;
  border: 1px solid #d9d9d9;
}
</style>
