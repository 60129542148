import { createStore } from 'vuex';
import user from './modules/user';
import permission from './modules/permission'
import baseService from '@/utils/http/axios'
import { systemConfig } from './reactiveState';
const store = createStore({
    modules: {
        user,
        permission,
        systemConfig
    },
    state: {
        showInform: false,
        verificationCount: {

        },
    },

    mutations: {
        SET_SHOW_INFORM: (state, showInform) => {
            state.showInform = showInform
        },
        SET_VCOUNT: (state, count) => {
            state.verificationCount = count
        }
    },

    actions: {
        // 查询未核验数量
        VerificationCount({ commit }, userInfo) {
            let requeryParam = {
                url: '/admin/v1/userInfo',
                method: 'get',
            }
            baseService(requeryParam).then((res: any) => {
                commit('SET_USER_INFO', res.data.userinfo)
                commit('SET_FILINGS_INFO', res.data.filings_info)
                commit('SET_IMAGE_DOMAIN', res.data.config.image_domain)
            }).catch(error => {
            })
        },
    }
});

export default store
