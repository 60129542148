import { Component } from './../../router/types';
import baseService from '@/utils/http/axios'
import ls from '@/utils/Storage';
import { BasicLayout, RouteView } from '@/layouts'
import { resetRouter } from '@/router'
import { asyncRouterMap } from '@/config/router.config'

/**
 * 向后端请求用户的菜单，动态生成路由
 */

const permission = {
    state: {
        addRouters: [],//路由和菜单
        addActions: [],//按钮权限
        addRoles: []//角色
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
        },
        SET_ACTIONS: (state, actions) => {
            state.addActions = actions
        },
        SET_ROLES: (state, roles) => {
            state.addRoles = roles
        }
    },
    actions: {
        GenerateRoutes({ commit }, router) {
            if (!ls.get('ACCESS_TOKEN')) {
                resetRouter(router)
                return
            }
            return new Promise((resolve, reject) => {
                //动态去后台查权限,更新路由和菜单
                let requeryParam = {
                    url: '/admin/v1/userPermission',
                    method: 'get'
                }
                baseService(requeryParam).then((res: any) => {
                    resetRouter(router)
                    if (res.code == 200) {
                        //1转换权限和菜单的变量
                        let routers = res.data.routers
                        routers.component = BasicLayout
                        routers.children.forEach((item: any) => {
                            /* 没有找到动态路由的import方式,如下代码会报错
                            if (item.component == 'RouteView') {
                                item.component = RouteView
                                item.children.forEach((son: any) => {
                                    son.component = () => import(`@${son.component}`)
                                })
                            } else {
                                item.component = () => import(`@${item.component}`)
                            }
                             */
                            if (item.component == 'RouteView') {
                                item.component = RouteView
                                item.children.forEach((son: any) => {
                                    son.component = asyncRouterMap[son.name] || null
                                })
                            } else {
                                item.component = asyncRouterMap[item.name] || null
                            }

                        })
                        commit('SET_ROUTERS', routers.children)
                        router.addRoute(routers)
                        //2可以将按钮权限存入localStorage
                        commit('SET_ACTIONS', res.data.actions)
                        commit('SET_ROLES', res.data.roles)
                        ls.set('USER_routers', routers);//刷新页面的时候先从这里取
                        resolve(routers)
                    } else {
                        //权限请求失败，清空菜单
                        commit('SET_ROUTERS', [])
                        reject(res.msg)
                    }
                }).catch(error => {
                    resetRouter(router)
                    commit('SET_ROUTERS', [])
                    reject(error)
                })
            })
        }
    }
}

export default permission
