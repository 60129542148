<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Logo',
})
</script>
<template>
  <div class="logo">
    <router-link :to="{ path: '/' }">
      <!-- v-if="$store.state.systemConfig.theme == 'light'" -->
      <SvgIcon name="logo" />
      <!-- <SvgIcon name="logo1" v-else /> -->
      <h1 v-if="showTitle">{{ title }}</h1>
    </router-link>
  </div>
</template>

<script lang="ts" setup name="Logo">
import SvgIcon from '@/components/SvgIcon/index.vue'
import { useStore } from 'vuex'
const $store = useStore()

const props = defineProps({
  title: {
    type: String,
    default: '创客企业服务',
    required: false
  },
  showTitle: {
    type: Boolean,
    default: true,
    required: false
  }
})
</script>
