<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="平台" name="platform_id">
              <a-select mode="multiple" v-model:value="queryParam.platform_id" placeholder="请选择">
                <a-select-option v-for="(item, index) in data.platformList" :key="index" :value="item.id">{{ item.platform_abbreviation }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="公司名称" name="nameOrCode">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入公司名称、税号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="续费联系人号码" name="renew_first_contact_mobile">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.renew_first_contact_mobile" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="代理商姓名" name="seller">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.seller" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <template v-if="data.advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="法人代表姓名" name="fr_name">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.fr_name" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="地址编号" name="address_number">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.address_number" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="主体类型" name="main_type">
                <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择">
                  <a-select-option :value="1">公司</a-select-option>
                  <a-select-option :value="2">个体</a-select-option>
                  <a-select-option :value="3">个人独资</a-select-option>
                  <a-select-option :value="4">合伙企业</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="收费类型" name="fee_standard">
                <a-select mode="multiple" v-model:value="queryParam.fee_standard" placeholder="请选择">
                  <a-select-option :value="1">按年收费</a-select-option>
                  <a-select-option :value="2">一次性</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="付款状态" name="is_pay">
                <a-select mode="multiple" v-model:value="queryParam.is_pay" placeholder="请选择">
                  <a-select-option :value="1">已付款</a-select-option>
                  <a-select-option :value="2">未付款</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="入驻类型" name="business_type">
                <a-select mode="multiple" v-model:value="queryParam.business_type" placeholder="请选择">
                  <a-select-option :value="1">设立</a-select-option>
                  <a-select-option :value="2">同区迁入</a-select-option>
                  <a-select-option :value="3">跨区迁入</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="进度状态" name="order_status">
                <a-select mode="multiple" :disabled="queryParam.order_status" v-model:value="queryParam.order_status_list" placeholder="请选择" @change="orderStatusChange" @select="orderStatusSelect">
                  <a-select-option v-for="(item, index) in data.progressOptions" :key="index" :value="item.value">{{ item.text }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="录入系统时间" name="between_time">
                <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否查询已删除客户" name="recycle">
                <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="queryParam.recycle"></a-switch>
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>

      <div class="table-operator">
        <div></div>
        <div class="table-page-search-submitButtons" style="float: right; overflow: hidden; margin-bottom: 0">
          <a-button type="primary" @click="loadData" :icon="h(SearchOutlined)">查询</a-button>
          <a @click="toggleAdvanced" style="margin-left: 8px">
            {{ data.advanced ? '收起' : '展开' }}
            <UpOutlined v-if="data.advanced" />
            <DownOutlined v-else />
          </a>
          <a-popover title="导出参数配置" trigger="click" :overlayStyle="{ width: '60%' }" placement="left" v-model:open="exportDataArgumentChecked.visible">
            <template #content>
              <a-tabs v-model:activeKey="exportDatatab">
                <a-tab-pane key="1" tab="根据搜索参数直接导出"
                  ><a-descriptions :column="1">
                    <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                      <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                      <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="导出字段">
                      <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                    </a-descriptions-item>
                    <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                      <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" @click="exportClick(1)" :loading="data.exportLoading">导出</a-button>
                    </a-descriptions-item>
                  </a-descriptions></a-tab-pane
                >
                <a-tab-pane key="2" tab="根据输入的公司名称导出详细信息（选择此项，搜索条件将失效）"
                  ><a-descriptions :column="1">
                    <a-descriptions-item label="公司名称" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                      <a-textarea v-model:value="exportDataArgumentChecked.companylist" placeholder="请输入公司名称" allow-clear :rows="6" />
                    </a-descriptions-item>
                    <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                      <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                      <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="导出字段">
                      <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                    </a-descriptions-item>
                    <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                      <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" @click="exportClick(2)" :loading="data.exportLoading">导出</a-button>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-tab-pane>
              </a-tabs>
            </template>
            <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" :loading="data.exportLoading">导出</a-button>
          </a-popover>
        </div>
      </div>
    </div>
    <div class="tabs">
      <a-affix offset-top="0">
        <a-tabs v-model:activeKey="data.tabsKey" @change="tabsChange" style="background: #fff">
          <a-tab-pane :key="1" tab="我的代办事项"> </a-tab-pane>
          <a-tab-pane :key="2" tab="领证"> </a-tab-pane>
          <a-tab-pane :key="3" tab="刻章"> </a-tab-pane>
          <a-tab-pane :key="4" tab="领章"> </a-tab-pane>
          <a-tab-pane :key="5" tab="刻章台账"> </a-tab-pane>
          <a-tab-pane :key="6" tab="上传营业执照"> </a-tab-pane> </a-tabs
      ></a-affix>
      <a-popconfirm :title="renwudating.confirmtext" ok-text="确定" cancel-text="取消" @confirm="download_sealbook">
        <a-button type="primary" v-if="data.tabsKey == 3" :loading="data.download_sealbook_loading" style="float: right; margin-top: -55px">
          <template #icon> <DownloadOutlined /> </template>下载所有要刻章单位的授权委托书</a-button
        ></a-popconfirm
      >
    </div>
    <a-table
      rowClassName="tableRowClass"
      :row-selection="{ selectedRowKeys: renwudating.selectids, type: 'multiple', onChange: onSelectChange }"
      :columns="tabcolumns"
      :loading="data.loading"
      :data-source="dataSource"
      :pagination="pagination"
      @change="onChange"
    >
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'companyInformation'">
          <div class="companyInformation" :style="[5, 6].includes(record.status) ? { borderLeft: '10px dashed #000' } : {}">
            <div class="mv-1x">
              <span class="mr-1x pointer" :style="record.status === 5 ? { textDecorationLine: 'line-through' } : {}" v-html="record.fullName"></span>
              <a-tag color="blue" v-if="record.business_type == 2">同区迁入</a-tag>
              <a-tag color="blue" v-if="record.business_type == 3">跨区迁入</a-tag>
            </div>
            {{ record.social_credit_code }}
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'full_address_number'">
          <div @click="handleCopyClick(text)" class="pointer">
            <div v-if="record.raw_address?.length > 0" style="border-bottom: 1px dashed #ccc">由：{{ record.raw_address }}</div>
            <div><span v-if="record.raw_address?.length > 0">迁到：</span>{{ text }}</div>
          </div>
        </template>

        <template v-else-if="column.dataIndex === '自动发送刻章资料'">
          <div v-if="record.auto_notice_status == 1">
            <p>
              <a-tag color="green">{{ record.dbname }} 已发送成功√</a-tag>
            </p>
            <p>{{ record.auto_notice_time }}</p>
          </div>
          <div v-else-if="record.auto_notice_status == 2">
            <p>
              <a-tag color="red">{{ record.dbname }} 发送失败x</a-tag>
            </p>
            <p>{{ record.auto_notice_time }}</p>
          </div>
        </template>

        <template v-else-if="column.dataIndex === '下载刻章资料'">
          <a @click="download_sealzip(record)"><DownloadOutlined /> 下载刻章资料</a>
          <p v-for="(item, idx) in record.zipdownload_record" :key="idx">{{ item }}</p>
        </template>

        <template v-else-if="column.dataIndex === 'status'">
          <div v-if="record.customer_work_order_status">
            <p class="mb-x">
              {{ record.customer_work_order_status.after_status_name }}
            </p>
            <p class="mb-x">
              {{ record.customer_work_order_status.created_at }}
            </p>
          </div>
          <div v-else>-</div>
        </template>

        <template v-else-if="column.dataIndex === 'seller'">
          <p class="mb-x">{{ record.seller ? record.seller : '-' }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'created_at'">
          <p v-if="data.tabsKey == 5">{{ record.notice_time }}</p>
          <p v-else class="mb-x">{{ record.created_at ? record.created_at : '-' }}</p>
        </template>
      </template>
    </a-table>
  </a-card>
  <a-affix offset-bottom="8" style="margin-left: 30px" v-if="renwudating.submibuttonshowflag">
    <a-popconfirm :title="renwudating.confirmtext + '(已选' + renwudating.selectids.length + '个)'" ok-text="确定" cancel-text="取消" @confirm="submit_renwudating">
      <a-button type="primary">提交</a-button></a-popconfirm
    >
  </a-affix>
  <a-modal v-model:open="data.downloadOpen" title="提示" @ok="handleDownloadOk">
    <p>资料已准备完毕，确定下载</p>
  </a-modal>
</template>

<script lang="ts" setup name="CustomerList">
import SvgIcon from '@/components/SvgIcon/index.vue'
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined } from '@ant-design/icons-vue'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import Record from './components/Record.vue'
import { useRouter } from 'vue-router'
import { EditOutlined } from '@ant-design/icons-vue'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'
const shareholderColumns: Object[] = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '职位',
    dataIndex: 'position_name'
  },
  {
    title: '出资金额',
    dataIndex: 'investment_money'
  },
  {
    title: '出资截止日期',
    dataIndex: 'subscribe_endtime'
  }
]

interface data {
  key: string | number
  customerNavig: any
  entryAgreementVisible: boolean
  recordVisible: boolean
  addTableVisible: boolean
  signatureStatusVisible: boolean
  directPaymentVisible: boolean
  platformVisible: boolean
  pushPaymentVisible: boolean
  pushOrderMakingVisible: boolean
  SFMailVisible: boolean
  addressRenewVisible: boolean
  visibleLog: boolean
  visibleStatus: boolean
  optionsVisible: boolean
  visible: boolean
  advanced: boolean
  tableColumnsData: Object
  loading: boolean
  editVisible: boolean
  exportLoading: boolean
  logTabsKey: number
  createKey: number
  platformList: any[]
  downList: any
  customerInfo: any
  progressOptions: any[]
  tabsKey: number
  chat_read_count: number
  downloadOpen: boolean
  downloadUrl: string
  download_sealbook_loading: boolean
}
const data = reactive<data>({
  key: '',
  customerNavig: {},
  exportLoading: false,
  // 入驻协议
  entryAgreementVisible: false,
  // 档案管理
  recordVisible: false,
  // 打标签
  addTableVisible: false,
  // 查看签名状态
  signatureStatusVisible: false,
  // 付款弹窗
  directPaymentVisible: false,
  platformVisible: false,
  // 推送付款
  pushPaymentVisible: false,
  // 推送做单
  pushOrderMakingVisible: false,
  // 顺丰邮寄
  SFMailVisible: false,
  //   地址续费
  addressRenewVisible: false,
  visibleLog: false,
  visibleStatus: false,
  // 操作弹窗
  optionsVisible: false,
  // create model
  visible: false,
  // 高级搜索 展开/关闭
  advanced: false,
  tableColumnsData: {},
  loading: false,
  // 编辑
  editVisible: false,
  // 进入日志 还是进入状态
  logTabsKey: 1,
  createKey: 1,
  platformList: [],
  /* 下载列表 */
  downList: {},
  //数据列表
  customerInfo: {},
  // 进度状态
  progressOptions: [],
  tabsKey: 3,
  chat_read_count: 0,
  //下载转换后的确认 弹窗
  downloadOpen: false,
  downloadUrl: '',
  download_sealbook_loading: false
})
const filteredInfo = ref()
const createForm = ref()

const columns = computed(() => {
  const filtered = filteredInfo.value || []
  const progressOptions = data.progressOptions
  return [
    {
      title: '公司名称',
      dataIndex: 'companyInformation',
      width: 300
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      width: 200
    },
    {
      title: '注册地址',
      dataIndex: 'full_address_number',
      width: 300
    },
    {
      title: '自动发送刻章资料',
      dataIndex: '自动发送刻章资料',
      width: 200
    },
    {
      title: '下载刻章资料',
      dataIndex: '下载刻章资料',
      width: 300
    },
    {
      title: '代理商',
      dataIndex: 'seller'
    },
    {
      title: '创建时间',
      dataIndex: 'created_at'
    }
  ]
})
//列表的选择列和操作
const renwudating = reactive({
  selectids: <any>[],
  submibuttonshowflag: false,
  type: 0, //选项卡序号：1代办事项，2领证，3刻章，4领章，5刻章台账，6上传营业执照
  confirmtext: ''
})
//初始化选择框
const onSelectInit = (type) => {
  renwudating.selectids = []
  renwudating.submibuttonshowflag = false
  if (type) {
    renwudating.type = type
    let typeName = { 1: '代办事项', 2: '领证', 3: '刻章', 4: '领章', 5: '刻章台账', 6: '上传营业执照' }
    renwudating.confirmtext = typeName[type]
  }
}
const onSelectChange = (selectedRowKeys: any[], val) => {
  if (selectedRowKeys.length > 0) {
    renwudating.submibuttonshowflag = true
  } else {
    renwudating.submibuttonshowflag = false
  }
  renwudating.selectids = selectedRowKeys
}
const submit_renwudating = () => {
  //发送请求批量处理
  console.log(renwudating.selectids)
  let requestParam = {
    url: '/admin/v1/customerList_renwudating_batch',
    method: 'post',
    data: {
      type: renwudating.type,
      customer_ids: renwudating.selectids
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
//列表的选择列和操作end

const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: [1009, 7004, 6, 7],
  main_type: [1, 3, 4],
  business_type: [1],
  platform_id: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '100', '200', '400', '500', '600'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  platformList()
  customerNavig()

  tabsChange(3)
  loadData()
})
onActivated(() => {
  queryParam.nameOrCode = router.currentRoute.value.query.customer_name
  if (router.currentRoute?.value?.query?.customer_name) {
    loadData()
  }
})
// 跳转天眼查
const handleDoubleNameClick = (url) => {
  window.open(url)
}
const foundingTime = (val) => {
  if (!val) return null
  return dayjs(val, 'YYYY-MM-DD')
}

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}
// 复制
const handleCopyClick = (val) => {
  var input = document.createElement('input')
  //将input的值设置为需要复制的内容
  input.value = val
  //添加input标签
  document.body.appendChild(input)
  //选中input标签
  input.select()
  //执行复制
  document.execCommand('copy')
  //成功提示信息
  message.success('复制成功!')
  //移除input标签
  document.body.removeChild(input)
}
// 复制名称
const handleNameClick = (e, name) => {
  if (e.detail == 1) {
    var input = document.createElement('input')
    //将input的值设置为需要复制的内容
    input.value = name
    //添加input标签
    document.body.appendChild(input)
    //选中input标签
    input.select()
    //执行复制
    document.execCommand('copy')
    //成功提示信息
    message.success('复制成功，尝试双击有惊喜！')
    //移除input标签
    document.body.removeChild(input)
  }
}

// 进度状态选择
const orderStatusChange = (val) => {}
// 进度状态选择
const orderStatusSelect = (val) => {
  if (val == 'blz') {
    queryParam.order_status_list = ['blz']
    data.tabsKey = 1
  } else {
    data.tabsKey = 2
    queryParam.order_status_list = queryParam.order_status_list.filter((item) => item != 'blz')
  }
  filteredInfo.value = queryParam.order_status_list
}
const formatDuration = (duration: number) => {
  const days: any = Math.floor(duration / (24 * 60 * 60))
  if (days > 0 && days <= 30) {
    return '到期前30天内'
  } else if (days > 30 && days <= 60) {
    return '到期前60天内'
  } else if (days < 0 && days >= -30) {
    return '到期后30天内'
  } else if (days < -30 && days >= -60) {
    return '到期后60天内'
  } else if (days < -60 && days >= -90) {
    return '到期后90天内'
  } else if (days < -90) {
    return '到期90天后客户,即将申报工商地址异常'
  } else {
    return null
  }
}

// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerList_renwudating',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, isEdit1: false, isEdit2: false, isEdit3: false }
      })
      if (data.progressOptions != res.data.order_status_list) {
        data.progressOptions = res.data.order_status_list
      }
      onSelectInit(0)
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
const customerNavig = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerNavig',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      Object.assign(data.customerNavig, res.data)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取统计失败')
      }
    })
}
// 获取平台数据
const platformList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: { limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      data.platformList = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('平台列表查询失败,请联系管理员')
      }
    })
}
const tabcolumns: any = ref([])
const tabcolumns1 = [
  {
    title: '公司名称',
    dataIndex: 'companyInformation'
  },
  {
    title: '当前状态',
    dataIndex: 'status',
    width: 200
  },
  {
    title: '注册地址',
    dataIndex: 'full_address_number'
  },

  {
    title: '创建时间',
    dataIndex: 'created_at'
  },
  {
    title: '代理商',
    dataIndex: 'seller'
  }
]
const tabcolumns2 = [
  {
    title: '公司名称',
    dataIndex: 'companyInformation',
    width: 300
  },
  {
    title: '当前状态',
    dataIndex: 'status',
    width: 200
  },
  {
    title: '注册地址',
    dataIndex: 'full_address_number',
    width: 300
  },
  {
    title: '自动发送刻章资料',
    dataIndex: '自动发送刻章资料',
    width: 200
  },
  {
    title: '下载刻章资料',
    dataIndex: '下载刻章资料',
    width: 300
  },
  {
    title: '代理商',
    dataIndex: 'seller'
  },
  {
    title: '创建时间',
    dataIndex: 'created_at'
  }
]

const tabsChange = (val) => {
  if (val == 1) {
    tabcolumns.value = tabcolumns1
  } else if (val == 2) {
    //领证刻章
    tabcolumns.value = tabcolumns1
    queryParam.order_status_list = [7004, 1009]
    queryParam.main_type = [1, 2, 3, 4]
    queryParam.business_type = []
  } else if (val == 3) {
    tabcolumns.value = tabcolumns2
    queryParam.order_status_list = [1009, 7004, 6, 7]
    queryParam.main_type = [1, 3, 4]
    queryParam.business_type = [1]
  } else if (val == 4) {
    queryParam.order_status_list = [8]
    queryParam.main_type = [1, 3, 4]
    queryParam.business_type = [1]
  } else if (val == 5) {
    queryParam.order_status_list = []
    queryParam.main_type = [1, 3, 4]
    queryParam.business_type = [1]
  }
  onSelectInit(val)
  //重新查数据
  pagination.current = 1
  queryParam.action_type = val
  loadData()
}
// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}
const exportDataArgument = [
  '主体名称',
  '统一社会信用代码',
  '法人代表姓名',
  '注册地址',
  '合同开始时间',
  '合同结束时间',
  '主体类型',
  '入驻类型',
  '成立时间',
  '核准日期',
  '录入时间',
  '主体状态',
  '异常状态',
  '法人代表电话',
  '工商预留手机号码',
  '紧急联系人电话',
  '其他电话',
  '是否支付',
  '归属代理商',
  '财务会计电话(续费联系人)',
  '财务公司名称',
  '财务公司对接人姓名',
  '财务公司对接人电话',
  '财务公司统一社会信用代码',
  '销售价格',
  '来年续费价格'
]
const exportDataArgumentChecked = reactive({
  visible: false,
  companylist: '',
  checkedfieldSimple: ['主体名称', '统一社会信用代码', '法人代表姓名', '注册地址', '合同开始时间', '合同结束时间', '主体类型', '入驻类型', '成立时间', '核准日期', '录入时间', '主体状态', '异常状态'],
  checkedfieldAll: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态',
    '法人代表电话',
    '工商预留手机号码',
    '紧急联系人电话',
    '其他电话',
    '是否支付',
    '归属代理商',
    '财务会计电话(续费联系人)',
    '财务公司名称',
    '财务公司对接人姓名',
    '财务公司对接人电话',
    '财务公司统一社会信用代码',
    '销售价格',
    '来年续费价格'
  ],
  checkedfieldSimpleBak: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态'
  ]
})
const selectExportDataArgument = (type) => {
  if (type == 1) {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldSimpleBak
  } else {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldAll
  }
}

const exportDatatab = ref('1')

const exportClick = (type) => {
  data.exportLoading = true
  exportDataArgumentChecked.visible = false
  queryParam.exportfield = exportDataArgumentChecked.checkedfieldSimple
  queryParam.type = type
  if (type == 2) {
    queryParam.companylist = exportDataArgumentChecked.companylist
  }
  queryParam = { ...queryParam, simple: 1 }

  let requestParam = {
    url: '/admin/v1/customerExport',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      responseType: 'blob'
    },
    data: queryParam
  }
  baseService(requestParam)
    .then((res) => {
      data.exportLoading = false
      message.success('下载成功')
    })
    .catch((res) => {
      data.exportLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('下载失败')
      }
    })
}

const toggleAdvanced = () => {
  data.advanced = !data.advanced
}

//下载刻章资料
const download_sealzip = (record) => {
  let requestParam = {
    url: '/admin/v1/downNoticeSeal',
    method: 'post',
    data: { customer_id: record.id }
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.msg == 1) {
        message.success('正在生成中，请稍后下载！')
      } else if (res.msg == 2) {
        //跳转地址下载
        window.open('/hncskaiyela/admin/v1/templateZipDown?id=' + res.data + '&token=' + token)
      } else if (res.msg == 3) {
        message.success('生成中，请稍后下载！')
      }
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取刻章材料失败')
      }
    })
}
//下载所有要刻章单位的授权委托书
let downloadInterval
const download_sealbook = () => {
  data.download_sealbook_loading = true
  let requestParam = {
    url: '/admin/v1/downNoticeSealBook',
    method: 'post',
    data: {}
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.msg == 1) {
        message.success(res.data)
      } else if (res.msg == 2) {
        //循环查询
        clearInterval(downloadInterval)
        downloadInterval = setInterval(() => {
          let requestParam = {
            url: `/admin/v1/downNoticeSealBook`,
            method: 'POST',
            data: { rid: res.data }
          }
          baseService(requestParam)
            .then((res2: any) => {
              if (res2.msg == 3) {
                //跳转地址下载
                data.download_sealbook_loading = false

                let requestParam = {
                  url: '/admin/v1/listZipDown',
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    responseType: 'blob'
                  },
                  data: { list: res2.data, file_name: '所有授权我委托书', token: token }
                }
                baseService(requestParam)
                  .then((res) => {
                    message.success('下载成功')
                  })
                  .catch((res) => {
                    if (res.msg) {
                      message.error(res.msg)
                    } else {
                      message.error('下载失败')
                    }
                  })

                // data.downloadOpen = true
                // data.downloadUrl = '/hncskaiyela/admin/v1/listZipDown?list=' + res2.data + '&file_name=所有授权我委托书&token=' + token
                clearInterval(downloadInterval)
              } else if (res2.msg == 5) {
                data.download_sealbook_loading = false
                message.error('下载失败！')
                clearInterval(downloadInterval)
              } else {
                console.log(res2)
              }
            })
            .catch((res) => {})
        }, 3000)
      }
    })
    .catch((res) => {
      data.download_sealbook_loading = false
      if (res.msg) {
        message.error(res.msg)
      }
    })
    .catch((res) => {
      data.download_sealbook_loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取刻章材料失败')
      }
    })
}
const handleDownloadOk = () => {
  window.open(data.downloadUrl)
}
</script>
<style lang="less" scoped>
.tabs {
  position: relative;
  .tabs-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 0;
    display: flex;
    gap: 32px;
    align-items: center;
  }
}
:deep(.tableRowClass) {
  .ant-table-cell:first-child {
    padding: 0 !important;
  }
}
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px !important;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}

.companyInformation {
  padding: 16px;
  .deng {
    display: none;
  }
}

.companyInformation:hover {
  .deng {
    display: block;
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
</style>
