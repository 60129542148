import { UserLayout } from '@/layouts'
import errorPage from '@/views/exception/500.vue'

/**
 * 菜单对应的模板,动态import导入失败
 * 增加菜单权限时，需要这里配置一下
 */
export const asyncRouterMap = {
    'workOrder': () => import('@/views/workOrder/WorkOrderList.vue'), // 工单管理
    'personal': () => import('@/views/system/Personal.vue'), // 个人中心
    'acustomer.list': () => import('@/views/acustomer/CustomerList.vue'), // 客户管理
    'customer.list': () => import('@/views/customer/CustomerList.vue'), // 客户管理
    'customer.list@xf': () => import('@/views/customer/CustomerListxf.vue'), // 客户续费管理
    'customer.searchScope': () => import('@/views/customer/CustomerBusinessScope.vue'), // 客户经营范围
    'licenseLog.list': () => import('@/views/customer/pigeonhole.vue'), // 营业执照归档
    'AddressRenew': () => import('@/views/customer/AddressRenew.vue'), // 万能支付
    'ObtainExpirationDate': () => import('@/views/customer/ObtainExpirationDate.vue'), // 批量查到期时间
    'CustomerListSimple': () => import('@/views/customer/CustomerListSimple.vue'), // 更新核准日期
    'otherLogistic': () => import('@/views/logistic/OtherLogisticList.vue'), // 顺丰邮寄
    'tasklist': () => import('@/views/customer/tasklist.vue'), // 任务大厅
    'logout.list': () => import('@/views/logout/lst.vue'), // 注销控制台
    'logoutAll': () => import('@/views/logout/lstAll.vue'), // 所有注销客户
    'verification.list': () => import('@/views/verification/OtherData.vue'), // 资料审核
    'verificationReimburse': () => import('@/views/verification/Reimburse.vue'), // 报销审核
    'recordVerification': () => import('@/views/recordManagement/Verification.vue'), // 备案审核
    'PlatformVerification': () => import('@/views/verification/PlatformVerification.vue'), // 平台充值审核
    'PlatformRefund': () => import('@/views/verification/PlatformRefund.vue'), // 平台退款审核
    'customerVerification': () => import('@/views/verification/customerVerification.vue'), // 客户删除审核
    'publicTransfer': () => import('@/views/financialManagement/publicTransfer.vue'), // 对公账户转账
    'refund': () => import('@/views/verification/refund.vue'), // 退款审核
    'yichangAudit': () => import('@/views/verification/yichangAuditList.vue'), // 资料下载审核
    'customerAttrAdjust': () => import('@/views/verification/customerAttrAdjust.vue'), // 属性调整审核
    'platformManagement': () => import('@/views/platformManagement/PlatformManagement.vue'), // 平台管理
    'restrictedAccess': () => import('@/views/platformManagement/RestrictedAccess.vue'), // 限制准入
    'templateManagement': () => import('@/views/platformManagement/TemplateManagement.vue'), // 平台模板库管理
    'addressNumberManagement': () => import('@/views/system/AddressNumberManagement.vue'), // 地址编号管理
    'userManagement': () => import('@/views/userManagement/UserManagement.vue'), // 用户管理
    'authorityManagement': () => import('@/views/userManagement/AuthorityManagement.vue'), // 授权管理
    'allAuthorization': () => import('@/views/userManagement/AllAuthorityLst.vue'), // 用户权限分组
    'recharge': () => import('@/views/financialManagement/Recharge.vue'), // 充值明细
    'consumption': () => import('@/views/financialManagement/consumption.vue'), // 消费明细
    'MyCouponsList': () => import('@/views/coupons/MyCouponsList.vue'), // 我的优惠券
    'UsersMoneyLog': () => import('@/views/financialManagement/UsersMoneyLog.vue'), // 余额变动记录
    'operate': () => import('@/views/statistics/operate.vue'), // 运营情况
    'filingInformation': () => import('@/views/recordManagement/FilingInformation.vue'), // 渠道客戶备案
    'activity': () => import('@/views/activity/Activity.vue'), // 活动中心
    'inventory': () => import('@/views/activity/Inventory.vue'), // 购买库存地址
    'notificationManagement': () => import('@/views/system/NotificationManagement.vue'), // 群发工具
    'menuManagement': () => import('@/views/system/MenuManagement.vue'), // 菜单管理
    'reimburse': () => import('@/views/system/Reimburse.vue'), // 报销管理
    'AskForLeave': () => import('@/views/system/AskForLeave.vue'), // 请假管理
    'Setting': () => import('@/views/system/Setting.vue'), // 后台配置
    'Robots': () => import('@/views/robots/robotsList.vue'), // 机器人管理
    'Peer': () => import('@/views/addressBook/Peer.vue'), // 同行通讯录
    'Work': () => import('@/views/addressBook/Work.vue'), // 工作通讯录
    'AuthCode': () => import('@/views/addressBook/AuthCode.vue'), // 验证码
    'list': () => import('@/views/coupons/list.vue'), // 优惠券管理
    'annualInspectionlist': () => import('@/views/annualInspection/lst.vue'), // 工商年检
    'CompanyAnnouncements': () => import('@/views/systemNotice/CompanyAnnouncements.vue'), // 通知公告
    'NewsCenter': () => import('@/views/systemNotice/NewsCenter.vue'), // 新闻中心
    'FileDownload': () => import('@/views/systemNotice/FileDownload.vue'), // 文件下载
    'MyRecord': () => import('@/views/systemNotice/MyRecord.vue'), // 我的记事本
    'logManagement': () => import('@/views/log/logManagement.vue'), // 总日志查看
    'statusManagement': () => import('@/views/log/statusManagement.vue'), // 总状态查看
    'signatureStatusManagement': () => import('@/views/log/signatureStatusManagement.vue'), // 签字状态查看
    'loginLog': () => import('@/views/log/loginLog.vue'), // 登录日志
}
/**
 * 基础路由
 * 2024-11-28
 * @type { *[] }
 */
export const constantRouterMap = [
    {
        path: '/user',
        name: 'user',
        component: UserLayout,
        redirect: '/user/login',
        hidden: true,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login.vue'),
                meta: { title: '登录' }
            },
        ]
    },
    {
        path: '/Action_customer',
        name: 'Action_customer',
        component: () => import('@/views/action/Action.vue'),
        meta: { title: '特别行动' }
    },
    {
        path: '/exception/500',
        name: 'error',
        component: errorPage,
    },
    {
        path: '/:path(.*)',
        name: 'NoMatch',
        component: () => import('@/views/exception/404.vue'),
    }
]
